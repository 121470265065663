import { TargetSelect } from '../../spider/semantic-ui/Target';
import { VIEW_OPTIONS } from '../../helpers/workStationView';
import { TargetSelectOptions } from '../../helpers/types';


/**
 * Selector for which view the work station is using
 */
export default class PerformViewTargetSelect extends TargetSelect {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOptions(props): TargetSelectOptions {
        return VIEW_OPTIONS;
    }
}
