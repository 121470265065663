import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { RadanIntegration } from 'store/Workstation/RadanIntegration';
import RightDivider from 'spider/component/RightDivider';
import { observer } from 'mobx-react';
import { DownloadButton, CancelButton } from 'spider/semantic-ui/Button';
import { t } from 'i18n';
import { Icon, Loader, Table, Progress, Modal } from 'semantic-ui-react';
import { Operator } from 'store/Operator';
import { showSaveNotification } from 'helpers/notification';
import subscribe from 'decorator/subscribe';
import filesize from 'filesize.js';
import { v4 as uuid } from 'uuid'


@subscribe
@observer
export default class RadanImportModal extends Component {
  static propTypes = {
    afterUpload: PropTypes.func.isRequired,
    radanIntegration: PropTypes.instanceOf(RadanIntegration).isRequired,
    operator: PropTypes.instanceOf(Operator).isRequired,
  }

  /**
   * Keeps track files selected by the user to upload & process.
   */
  @observable selectedFiles = []

  /**
   * Keeps track of uploading of files.
   */
  @observable uploadTotal = 0
  @observable uploadCurrent = 0

  /**
   * Keeps track if modal is open or closed.
   */
  @observable isOpen = false
  open = () => this.isOpen = true
  close = () => this.isOpen = false

  subscribeToFileUpdates(file) {
    this.subscribe({ room: 'radan-integration-inbox', uuid: file.uuid }, payload => {
      file.progress = payload.data.progress

      if (payload.data.current) {
        file.current = payload.data.current
      }

      if (payload.data.total) {
        file.total = payload.data.total
      }

      if (payload.data.status === 'success') {
        file.isImported = true
      } else if (payload.data.status === 'error' && !file.errors) {
        file.errors = payload.data.errors || {}
      }

      this.forceUpdate()
    });
  }

  /**
   * Kick off uploading + processing files.
   */
  filesChange = async (e) => {
    const { radanIntegration, operator, afterUpload } = this.props
    const formData = new FormData()

    // Update selectedFiles with the newly selected files.
    this.selectedFiles = [...this.selectedFiles, ...e.target.files]

    // Only process those which are not yet uploaded.
    this.selectedFiles.filter(file => !file.isUploaded).forEach(file => {
      // It's possible to upload files with the same name, so we add a uuid.
      file.uuid = uuid()

      // Not really already uploaded, but more like handled to be uploaded.
      file.isUploaded = true

      // Abuse name to encode the uuid so backend can parse it.
      formData.append(`${file.name},${file.uuid}`, file)

      this.subscribeToFileUpdates(file)
    })

    try {
      // Upload files, and process them. The function `importXml` will only be
      // done after all files are uploaded & processed.
      const response = await radanIntegration.importXml(operator, formData, (totalBytes, currentBytes) => {
        this.uploadTotal = totalBytes
        this.uploadCurrent = currentBytes
      })

      // Process response. This is a bit duplication of websocket push
      // notification.
      // eslint-disable-next-line no-unused-vars
      for (const fileName in response.success) {
        const file = this.selectedFiles.find(file => file.name === fileName)

        if (file) {
          file.isImported = true
        }
      }

      // eslint-disable-next-line no-unused-vars
      for (const fileName in response.errors) {
        const file = this.selectedFiles.find(file => file.name === fileName)
        const errors = response.errors[fileName]

        if (file) {
          file.errors = errors
        }
      }
    } catch (e) {
      if (e.response.status === 400 && e.response.data.code === 'ValidationError') {
        // eslint-disable-next-line no-unused-vars
        for (const fileName in e.response.data.errors.radan_integration) {
          const file = this.selectedFiles.find(file => file.name === fileName)
          const errors = e.response.data.errors.radan_integration[fileName]

          if (file) {
            file.errors = errors
          }
        }
      }
    }

    showSaveNotification()
    afterUpload()
    this.forceUpdate()
  }

  render() {
    const { radanIntegration } = this.props;

    return (
      <Modal closeIcon
        open={this.isOpen}
        onClose={this.close}
        trigger={(
          <DownloadButton data-open-radan-import
            icon="upload"
            content={t('radanIntegration.uploadModal.trigger', { name: radanIntegration.integration.name })}
            onClick={this.open}
          />
        )}
      >
        <Modal.Header>{t('radanIntegration.uploadModal.title', { name: radanIntegration.integration.name })}</Modal.Header>
        <Modal.Content scrolling>
          {this.selectedFiles.length === 0
            ? t('radanIntegration.uploadModal.chooseFiles')
            : (
              <>
                <UploadProgress
                  uploadCurrent={this.uploadCurrent}
                  uploadTotal={this.uploadTotal}
                />
                <UploadedFilesProgress
                  files={this.selectedFiles}
                />
              </>
          )}
        </Modal.Content>
        <Modal.Actions>
            <CancelButton onClick={this.close} />
            <RightDivider />
            <UploadFilesButton onChange={this.filesChange} />
        </Modal.Actions>
      </Modal>
    )
  }
}


/**
 * Render an upload file button. It will add a hidden file upload to trigger the
 * browsers file browser window.
 */
class UploadFilesButton extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  }

  fileInputRef = React.createRef();

  render() {
    const { onChange } = this.props

    return (
      <>
        <DownloadButton data-test-import-select compact primary
          icon='upload'
          labelPosition='left'
          content={t('radan.import.select')}
          onClick={() => this.fileInputRef.current.click()}
        />
        <input hidden multiple data-test-import-input
          ref={this.fileInputRef}
          type="file"
          onChange={onChange}
        />
      </>
    )
  }
}

/**
 * Shows the progress of uploading files.
 */
class UploadProgress extends Component {
  static propTypes = {
    uploadCurrent: PropTypes.number.isRequired,
    uploadTotal: PropTypes.number.isRequired,
  }

  render() {
    const { uploadCurrent, uploadTotal } = this.props

    return (
      <Progress data-test-upload-progress-bar indicating autoSuccess
        value={uploadCurrent}
        total={uploadTotal}
      >
        Uploading: {filesize(uploadCurrent)} / {filesize(uploadTotal)}
      </Progress>
    )
  }
}

/**
 * Shows the progress of the processing of upploaded files.
 */
class UploadedFilesProgress extends Component {
  static propTypes = {
    files: PropTypes.array.isRequired,
  }

  render() {
    const { files } = this.props

    return (
      <Table>
        {files.map(file => {
          return (
            <Table.Row data-test-import-file={file.name}>
              <Table.Cell collapsing style={{ verticalAlign: 'top' }}>
                {renderFileIcon(file)}
              </Table.Cell>
              <Table.Cell collapsing data-test-import-filename={file.name}>
                {file.name}
              </Table.Cell>
              <Table.Cell>
                {file.total && !(file.errors || file.isImported) && (
                  <Progress data-test-progress-bar indicating autoSuccess
                    value={file.current || 0}
                    total={file.total}
                    progress="ratio"
                  />
                )}
                {file.errors && (
                  <ul data-test-errors>
                    {Object.keys(file.errors || {}).map(code => (
                      <li data-test-error>
                        <span data-test-error-code>{code}</span>
                        <ul>
                          {file.errors[code].map(error => (
                            <li data-test-error-message>{error.message}</li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                )}
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table>
    )
  }
}

/**
 * Renders an icon indicating the status of file, such as if it is uploaded or
 * has an error.
 */
function renderFileIcon(file) {
  if (file.isImported) {
    return <Icon data-test-imported-successfully color="green" name="check" />
  } else if (file.errors) {
    return <Icon data-test-has-errors color="red" name="warning circle" />
  } else if (file.progress !== undefined) {
    return  <Loader data-test-processing active inline size="tiny" />
  }

  return <Icon data-test-waiting name="question" />
}
