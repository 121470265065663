import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reaction, action, observable, computed, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Modal, Form, Button } from 'semantic-ui-react'
import { Document, Page, pdfjs }  from 'react-pdf';
import styled from 'styled-components'
import { TargetBase, stripQueryParams, TargetNumberInput, TargetSelect } from 'spider/semantic-ui/Target'
import { IconButton } from 'spider/semantic-ui/Button'
import { theme } from 'styles'
import { pick } from 'lodash'
import { opacify } from 'polished'
import TargetTemplate from './TargetTemplate'
import RightDivider from 'spider/component/RightDivider'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const A4 = {
  width: 595.2755905511812,
  height: 841.8897637795277,
}
// in px/pt
const SCALE = 600 / A4.height
const MIN_SIZE = 0.05
const MAX_CLICK_DIFF = 0.01

const DEFAULT_OVERLAY_FONT_TYPE = 'Helvetica-Bold'
const DEFAULT_OVERLAY_FONT_SIZE = 7

const PageContainer = styled.div`
  cursor: ${({ cursor }) => cursor};
  margin: 1rem;
  box-shadow: 0 0.375rem 1rem rgba(0, 0, 0, 0.25);

  position: relative;
  width: ${({ width }) => width * SCALE}px;
  height: ${({ height }) => height * SCALE}px;

  > .react-pdf__Page {
    position: absolute !important;
    left: 0;
    top: 0;
    transform: scale(${SCALE});
    transform-origin: top left;
    pointer-events: none !important;
    * {
      pointer-events: none !important;
    }
  }
`

const PageButton = styled(IconButton)`
  position: absolute;
  right: -1rem;
  top: ${({ index = 0 }) => index * 2 + 0.5}rem;
  font-size: 1.25rem !important;
  transform: translateX(100%);
  color: rgba(0, 0, 0, 0.25);
  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }
  transition: color 300ms ease;
`

const PageOverlay = styled.div`
  position: absolute;
  border-radius: 0.25rem;
  background-color: ${opacify(-0.5, theme.primaryColor)};
  border: 2px dashed ${theme.primaryColor};
  ${({ drag, dragging }) => {
    if (drag) {
      return `
      opacity: 1;
    `;
    }
    return dragging ? `
      opacity: 0.25;
    ` : `
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    `;

  }}
  transition: opacity 300ms ease;
`

const DRAG_WIDTH = 6
const DRAG_OFFSET = 2

const DragHandle = styled.div`
  position: absolute;
  ${({ top, left, right, bottom }) => top || bottom ? `
    cursor: ns-resize;
    height: ${DRAG_WIDTH}px;
    left: ${DRAG_WIDTH - DRAG_OFFSET}px;
    right: ${DRAG_WIDTH - DRAG_OFFSET}px;
    ${top && `top: ${-DRAG_OFFSET}px`};
    ${bottom && `bottom: ${-DRAG_OFFSET}px`};
  ` : `
    cursor: ew-resize;
    width: ${DRAG_WIDTH}px;
    top: ${DRAG_WIDTH - DRAG_OFFSET}px;
    bottom: ${DRAG_WIDTH - DRAG_OFFSET}px;
    ${left && `left: ${-DRAG_OFFSET}px`};
    ${right && `right: ${-DRAG_OFFSET}px`};
  `}
`

const StyledDocument = styled(Document)`
  &, > .react-pdf__message--no-data {
    display: flex;
    align-items: center;
    flex-direction: column;
    > .button {
      margin: 1rem !important;
    }
  }
`

const HalfTargetSelect = styled(TargetSelect)`
  width: 50%;
`

const HalfNumberInput = styled(TargetNumberInput)`
  width: 50%;
`

@observer
class TemplateModal extends Component {
  static propTypes = {
    template: PropTypes.string.isRequired,
    font_type: PropTypes.string.isRequired,
    font_size: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    allowedKeys: PropTypes.array.isRequired,
  }

  @observable template = this.props.template
  @observable font_type = this.props.font_type ? this.props.font_type : 'Helvetica'
  @observable font_size = this.props.font_size ? this.props.font_size : 11

  render() {
    const { onSubmit, onCancel, onDelete, allowedKeys } = this.props

    const fontTypeOptions = [
      'Helvetica',
      'Helvetica-Bold',
      'Times',
      'Times-Bold',
      'Times-Roman',
      'Courier',
      'Courier-Bold',
      'Code128',
      'Code39'
    ]

    return (
      <Modal open closeIcon onClose={onCancel}>
        <Modal.Header>{t('printStep.field.documentOverlays.template')}</Modal.Header>
        <Modal.Content>
          <Form>
            <TargetTemplate noLabel
              value={this.template}
              onChange={(template) => this.template = template}
              allowedKeys={allowedKeys}
            />
            <Form.Group>
              <HalfTargetSelect data-test-template-font-type-select
                name="font_type"
                label={t('printStep.field.documentOverlays.font_type')}
                options={fontTypeOptions.map((type) => ({
                  value: type,
                  text: type,
                }))}
                value={this.font_type}
                onChange={(font_type) => {
                  this.font_type = font_type
                }}
              />
              <HalfNumberInput data-test-template-font-size-input autoFocus
                allowNegative={false}
                label={t('printStep.field.documentOverlays.font_size')}
                value={this.font_size.toString()}
                onChange={(value) => this.font_size = parseFloat(value)}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="delete" labelPosition="left"
            content={t('form.deleteButton')}
            onClick={onDelete}
          />
          <RightDivider />
          <Button primary data-test-template-submit-button
            icon="check" labelPosition="left"
            content={t('form.submitButton')}
            onClick={() => onSubmit(this.template, this.font_type, this.font_size)}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default class TargetOverlays extends TargetBase {
  static propTypes = {
    ...TargetBase.propTypes,
    value: PropTypes.array,
    allowedKeys: PropTypes.array,
    background: PropTypes.string,
  }
  static defaultProps = {
    ...TargetBase.defaultProps,
    allowedKeys: [],
  }

  @observable background = null
  @observable backgrounds = []
  @observable pages

  @observable drag = null
  @observable edit = null

  @computed get pageOverlays() {
    const pageOverlays = {}
    for (let index = 0; index < this.value.length; index++) {
      const overlay = this.value[index]
      const page = Math.floor(overlay.y)
      if (pageOverlays[page] === undefined) {
        pageOverlays[page] = []
      }
      pageOverlays[page].push({ ...overlay, y: overlay.y - page, index })
    }
    return pageOverlays
  }

  constructor(...args) {
    super(...args)
    this.setPages = this.setPages.bind(this)
    this.onLoadBackground = this.onLoadBackground.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onTemplateSubmit = this.onTemplateSubmit.bind(this)
    this.onTemplateDelete = this.onTemplateDelete.bind(this)
    this.onTemplateCancel = this.onTemplateCancel.bind(this)
    this.setPages()
  }

  ignoreValueChange = false

  onChange(value) {
    this.ignoreValueChange = true
    setTimeout(() => this.ignoreValueChange = false, 0)
    return super.onChange(value)
  }

  componentDidMount() {
    super.componentDidMount()
    this.backgroundReaction = reaction(
      () => this.props.background,
      action((background) => {
        this.background = stripQueryParams(background)
        this.backgrounds = []
        this.setPages()
      }),
      { fireImmediately: true },
    )
    this.valueReaction = reaction(
      () => this.value,
      () => {
        if (!this.ignoreValueChange) {
          this.setPages()
        }
      },
    )
    window.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('mouseup', this.onMouseUp)
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    this.backgroundReaction()
    this.valueReaction()
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  onMouseMove(e) {
    if (!this.drag) {
      return
    }
    const mouseX = this.drag.mapX(e.clientX)
    const mouseY = this.drag.mapY(e.clientY)
    if (this.drag.type === 'create') {
      const rect = {
        ...this.drag.start,
        width: mouseX - this.drag.start.x,
        height: mouseY - this.drag.start.y,
      }
      if (rect.width < 0) {
        rect.width = -rect.width
        rect.x -= rect.width
      }
      if (rect.height < 0) {
        rect.height = -rect.height
        rect.y -= rect.height
      }
      this.drag = { ...this.drag, rect }
    } else if (this.drag.type === 'move') {
      const dx = mouseX - this.drag.start.x
      const dy = mouseY - this.drag.start.y
      const dragged = (
        this.drag.dragged ||
        Math.abs(dx) > MAX_CLICK_DIFF ||
        Math.abs(dy) > MAX_CLICK_DIFF
      )
      let { x, y, width, height } = this.value[this.drag.overlay]
      y -= this.drag.page
      const rect = {
        x: Math.min(Math.max(x + dx, 0), 1 - width),
        y: Math.min(Math.max(y + dy, 0), 1 - height),
        width,
        height,
      }
      this.drag = { ...this.drag, dragged, rect }
    } else if (this.drag.type === 'resize-top') {
      const dy = mouseY - this.drag.start.y
      let { x, y, width, height } = this.value[this.drag.overlay]
      y -= this.drag.page
      let newY = Math.min(Math.max(y + dy, 0), 1)
      let newHeight = (y + height) - newY
      if (newHeight < MIN_SIZE) {
        newY -= MIN_SIZE - newHeight
        newHeight = MIN_SIZE
      }
      const rect = { x, y: newY, width, height: newHeight }
      this.drag = { ...this.drag, rect }
    } else if (this.drag.type === 'resize-left') {
      const dx = mouseX - this.drag.start.x
      let { x, y, width, height } = this.value[this.drag.overlay]
      y -= this.drag.page
      let newX = Math.min(Math.max(x + dx, 0), 1)
      let newWidth = (x + width) - newX
      if (newWidth < MIN_SIZE) {
        newX -= MIN_SIZE - newWidth
        newWidth = MIN_SIZE
      }
      const rect = { x: newX, y, width: newWidth, height }
      this.drag = { ...this.drag, rect }
    } else if (this.drag.type === 'resize-right') {
      const dx = mouseX - this.drag.start.x
      let { x, y, width, height } = this.value[this.drag.overlay]
      y -= this.drag.page
      let newX = x
      let newWidth = Math.min(Math.max(x + dx + width, 0), 1) - newX
      if (newWidth < MIN_SIZE) {
        newWidth = MIN_SIZE
      }
      const rect = { x: newX, y, width: newWidth, height }
      this.drag = { ...this.drag, rect }
    } else if (this.drag.type === 'resize-bottom') {
      const dy = mouseY - this.drag.start.y
      let { x, y, width, height } = this.value[this.drag.overlay]
      y -= this.drag.page
      let newY = y
      let newHeight = Math.min(Math.max(y + dy + height, 0), 1) - newY
      if (newHeight < MIN_SIZE) {
        newHeight = MIN_SIZE
      }
      const rect = { x, y: newY, width, height: newHeight }
      this.drag = { ...this.drag, rect }
    }
  }

  @action onMouseUp(e) {
    if (!this.drag || e.button !== 0) {
      return
    }
    if (this.drag.type === 'create') {
      if (this.drag.rect.width >= MIN_SIZE && this.drag.rect.height >= MIN_SIZE) {
        const overlay = {
          ...this.drag.rect,
          y: this.drag.rect.y + this.drag.page,
          template: '',
          font_type: DEFAULT_OVERLAY_FONT_TYPE,
          font_size: DEFAULT_OVERLAY_FONT_SIZE,
        }
        this.onChange([...this.value, overlay])
      }
    } else if (this.drag.type === 'move' && !this.drag.dragged) {
      this.edit = this.drag.overlay
    } else {
      this.onChange([
        ...this.value.slice(0, this.drag.overlay),
        {
          ...this.value[this.drag.overlay],
          ...this.drag.rect,
          y: this.drag.rect.y + this.drag.page,
        },
        ...this.value.slice(this.drag.overlay + 1),
      ])
    }
    this.drag = null
  }

  async onLoadBackground(data) {
    const pagePromises = []
    for (let page = 1; page <= data.numPages; page++) {
      pagePromises.push(data.getPage(page).then((data) => ({
        width: data.view[2],
        height: data.view[3],
      })))
    }
    const backgrounds = await Promise.all(pagePromises)
    runInAction(() => {
      this.backgrounds = backgrounds
      this.setPages()
    })
  }

  @action setPages() {
    this.pages = Math.max(
      1,
      this.backgrounds.length,
      ...this.value.map(({ y }) => Math.floor(y) + 1),
    )
  }

  @action clearPage(page) {
    const overlays = []
    let changes = false

    // eslint-disable-next-line
    for (const overlay of this.value) {
      if (page <= overlay.y && overlay.y < page + 1) {
        changes = true
      } else {
        overlays.push(overlay)
      }
    }

    if (changes) {
      this.onChange(overlays)
    }
  }

  @action deletePage(page) {
    const overlays = []
    let changes = false

    // eslint-disable-next-line
    for (const overlay of this.value) {
      if (page <= overlay.y && overlay.y < page + 1) {
        changes = true
      } else if (page + 1 <= overlay.y) {
        overlays.push({ ...overlay, y: overlay.y - 1 })
        changes = true
      } else {
        overlays.push(overlay)
      }
    }

    if (changes) {
      this.onChange(overlays)
    }
    this.pages--
  }

  @action onTemplateSubmit(template, font_type, font_size) {

    this.onChange([
      ...this.value.slice(0, this.edit),
      { ...this.value[this.edit], template, font_type, font_size },
      ...this.value.slice(this.edit + 1),
    ])
    this.onTemplateCancel()
  }

  @action onTemplateDelete() {
    this.onChange([
      ...this.value.slice(0, this.edit),
      ...this.value.slice(this.edit + 1),
    ])
    this.onTemplateCancel()
  }

  @action onTemplateCancel() {
    this.edit = null
  }

  renderOverlay({ x, y, width, height }, {
    children = null,
    style = {},
    onDragTop,
    onDragLeft,
    onDragRight,
    onDragBottom,
    ...props
  } = {}) {
    style = {
      ...style,
      left: `${x * 100}%`,
      top: `${y * 100}%`,
      width: `${width * 100}%`,
      height: `${height * 100}%`,
    }
    return (
      <PageOverlay style={style} {...props}>
        {children}
        {onDragTop && <DragHandle top onMouseDown={onDragTop} />}
        {onDragLeft && <DragHandle left onMouseDown={onDragLeft} />}
        {onDragRight && <DragHandle right onMouseDown={onDragRight} />}
        {onDragBottom && <DragHandle bottom onMouseDown={onDragBottom} />}
      </PageOverlay>
    )
  }

  renderContent({ disabled }) {
    const { allowedKeys } = this.props

    let pages = []
    for (let page = 0; page < this.pages; page++) {
      let pagesize = A4
      if (page < this.backgrounds.length) {
        pagesize = this.backgrounds[page]
      } else if (this.backgrounds.length > 0) {
        pagesize = this.backgrounds[this.backgrounds.length - 1]
      }

      let cursor = ''
      if (!this.drag) {
        cursor = 'crosshair'
      } else if (this.drag.page !== page) {
        cursor = 'not-allowed'
      } else if (this.drag.type === 'move' && !this.drag.dragged) {
        cursor = 'pointer'
      } else {
        cursor = {
          create: 'crosshair',
          move: 'grabbing',
          'resize-top': 'ns-resize',
          'resize-left': 'ew-resize',
          'resize-right': 'ew-resize',
          'resize-bottom': 'ns-resize',
        }[this.drag.type]
      }

      pages.push(
        <PageContainer
          data-page-container={page}
          cursor={cursor}
          key={page}
          {...pagesize}
          onMouseDown={disabled ? undefined : (e) => {
            if (e.button !== 0) {
              return
            }
            e.preventDefault()
            let elem = e.target
            while (elem.dataset.pageContainer === undefined) {
              elem = elem.parentElement
            }
            const { x, y, width, height } = elem.getBoundingClientRect()
            function mapX(clientX) {
              return Math.min(Math.max((clientX - x) / width, 0), 1)
            }
            function mapY(clientY) {
              return Math.min(Math.max((clientY - y) / height, 0), 1)
            }
            const start = { x: mapX(e.clientX), y: mapY(e.clientY) }
            const rect = { ...start, width: 0, height: 0 }
            this.drag = { type: 'create', page, mapX, mapY, start, rect }
          }}
        >
          {page < this.backgrounds.length && (
            <Page pageIndex={page} />
          )}
          {!disabled && (
            <>
              <PageButton
                name="eraser"
                onClick={() => this.clearPage(page)}
              />
              {this.pages > 1 && page >= this.backgrounds.length && (
                <PageButton
                  index={1}
                  name="delete"
                  onClick={() => this.deletePage(page)}
                />
              )}
            </>
          )}
          {this.pageOverlays[page] && this.pageOverlays[page].map((overlay, i) => {
            const props = {
              key: i,
              dragging: this.drag !== null,
            }
            if (this.drag !== null && this.drag.overlay === overlay.index) {
              props.drag = true
              if (this.drag.type !== 'move' || this.drag.dragged) {
                overlay = { ...overlay, ...this.drag.rect }
              }
            }
            if (!disabled && this.drag === null) {
              const dragFunc = (func) => (e) => {
                if (e.button !== 0) {
                  return
                }
                e.preventDefault()
                e.stopPropagation()
                let elem = e.target
                while (elem.dataset.pageContainer === undefined) {
                  elem = elem.parentElement
                }
                const { x, y, width, height } = elem.getBoundingClientRect()
                function mapX(clientX) {
                  return Math.min(Math.max((clientX - x) / width, 0), 1)
                }
                function mapY(clientY) {
                  return Math.min(Math.max((clientY - y) / height, 0), 1)
                }
                const rect = pick(overlay, 'x', 'y', 'width', 'height')
                this.drag = {
                  overlay: overlay.index,
                  mapX, mapY, page, rect,
                  ...func(mapX(e.clientX), mapY(e.clientY))
                }
              }
              props.onMouseDown = dragFunc((x, y) => ({ type: 'move', dragged: false, start: { x, y } }))
              props.onDragTop = dragFunc((x, y) => ({ type: 'resize-top', start: { x, y } }))
              props.onDragLeft = dragFunc((x, y) => ({ type: 'resize-left', start: { x, y } }))
              props.onDragRight = dragFunc((x, y) => ({ type: 'resize-right', start: { x, y } }))
              props.onDragBottom = dragFunc((x, y) => ({ type: 'resize-bottom', start: { x, y } }))
            }
            return this.renderOverlay(overlay, props)
          })}
          {this.drag && this.drag.page === page && (
            this.renderOverlay(this.drag.rect, { drag: true })
          )}
        </PageContainer>
      )
    }

    const content = (
      <>
        {pages}
        {!disabled && (
          <Button primary
            icon="add" labelPosition="left"
            content={t('printStep.field.documentOverlays.addPage')}
            onClick={() => this.pages += 1}
          />
        )}
      </>
    )

    return (
      <>
        <StyledDocument
          file={this.background || undefined}
          options={{ disableWorker: false }}
          onLoadSuccess={this.onLoadBackground}
          loading="loading"
          error="error"
          noData={content}
        >
          {content}
        </StyledDocument>
        {this.edit !== null && (
          <TemplateModal
            template={this.value[this.edit].template}
            font_type={this.value[this.edit].font_type}
            font_size={this.value[this.edit].font_size}
            onSubmit={this.onTemplateSubmit}
            onCancel={this.onTemplateCancel}
            onDelete={this.onTemplateDelete}
            allowedKeys={allowedKeys}
          />
        )}
      </>
    )
  }
}
