import React from 'react'
import { observer } from 'mobx-react'
import BaseLogin from 'spider/semantic-ui/Login'
import { Body, ContentContainer } from 're-cy-cle'
import { Divider, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Sidebar as BaseSidebar, SidebarContent } from 'spider/semantic-ui/Admin/Overview'
import TrippyBackground from 'component/TrippyBackground'
import Logo from 'image/logo.svg'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'


const SiteLogo = styled.svg`
    width: 1.50em;
    height: 1.50em;
    position: relative;
    top: 0.40em;
    margin-right: 0.375em;
`;

export const BrandingContainer = styled.a`
    display: block;
    text-align: center;
    margin-top: 25px;
    font-weight: bold;
    font-size: 1.25rem;

    color: rgba(0, 0, 0, 0.3);
    transition: color 600ms ease, filter 600ms ease, transform 600ms ease;
    .yellow {
        transition: color 600ms ease;
    }

    &:hover {
        color: #2d303b;
        filter: drop-shadow(0 0 5px #FFF);
        transform: scale(1.05);
        .yellow {
            color: #ebbb12;
        }
    }
`;

const Sidebar = styled(BaseSidebar)`
  background-color: #fff;
  ${SidebarContent} {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const BlockLink = styled(Link)`
    display: block;
    margin: 1.5em auto;
`;

const HeaderImg = styled.img`
    height: 3em;
`;

export function sidebarTheme(Screen) {
  return observer(class extends Screen {

    brandingFlowmasters() {
      return (
        <BrandingContainer
          href="https://www.flowmasters.it/"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          <SiteLogo width="51" height="51" viewBox="0 0 51 51">
            <ellipse cx="25.1858" cy="25.4686" rx="24.4136" ry="24.4136" fill="#EEEEEE"/>
            <path fill="currentColor" d="
              M 21.9573 5.72649C17.6977 6.36543 13.9554 8.37352 10.6694 11.8725C6.86622 15.8582 5.25366 19.9352 5.25366 25.6248C5.25366 28.4848 5.37536 29.3672 6.10558 31.6491C7.23132 35.0872 9.84792 39.1642 10.9432 39.1642C11.1562 39.1642 12.0994 38.2514 13.0426 37.1865L14.7769 35.1784L13.5903 33.5963C6.86622 24.7729 10.3652 12.1767 20.6186 8.13011C24.0262 6.79139 29.8071 6.73054 33.3973 7.97798C35.3445 8.67777 35.2228 8.25181 33.2148 7.36947C29.7158 5.81777 25.4562 5.17883 21.9573 5.72649Z
            "/>
            <path fill="currentColor" d="
              M 37.0483 13.8805L35.1924 15.9495L36.2268 17.2882C37.596 19.1137 38.7522 21.4565 39.2998 23.5559C39.8171 25.5639 39.8779 29.854 39.4215 31.862C38.2045 37.1561 33.4581 41.9329 27.7381 43.6063C24.2696 44.6103 17.6977 44.0627 14.8377 42.5414C14.503 42.3284 14.1987 42.2676 14.1987 42.3284C14.1987 42.6023 17.2109 44.0627 19.0668 44.7016C22.9004 46.0404 28.377 45.9186 32.1194 44.4278C38.6609 41.8416 43.3768 35.9391 44.533 28.8803C44.9894 25.9899 44.9894 25.5639 44.533 22.7648C43.8637 18.6269 42.5249 15.2497 40.7907 13.3024C40.03 12.481 39.2998 11.7812 39.1477 11.7812C39.026 11.7812 38.0828 12.7244 37.0483 13.8805Z
            " />
            <path fill="currentColor" d="
              M 22.5659 13.0895C13.7424 15.4018 10.0914 25.7769 15.5984 33.0182C16.359 33.9918 16.3895 34.0222 16.998 33.4746C17.3327 33.1703 17.8803 32.5618 18.1846 32.1359L18.7627 31.3144L17.7282 29.6105C16.4199 27.4503 15.9027 25.2901 16.1156 22.9778C16.6633 16.8014 23.1135 12.4201 29.2595 14.0326C30.2331 14.2761 31.1154 14.4586 31.1763 14.3978C31.2371 14.3369 30.4765 13.9718 29.5029 13.6067C27.3427 12.7852 24.5739 12.5722 22.5659 13.0895Z
            "/>
            <path fill="currentColor" d="
              M 32.8191 17.9575C32.2715 18.5052 31.8455 19.1137 31.8455 19.3266C31.8455 19.5092 32.2715 20.3307 32.7583 21.1218C34.4925 23.8601 34.9489 26.4158 34.1578 29.3367C33.2755 32.6835 30.6285 35.4218 27.3729 36.4258C24.9389 37.1865 24.087 37.1865 21.1966 36.4562C19.9491 36.1216 18.8842 35.9086 18.8234 35.939C18.7929 35.9999 19.584 36.3954 20.588 36.8518C22.87 37.8862 24.9085 38.1296 27.3425 37.6428C36.4398 35.8782 40.6081 25.2292 35.101 17.8054C34.2795 16.71 34.0057 16.7405 32.8191 17.9575Z
            "/>
          </SiteLogo>
          {t('flowmastersBranding')}
        </BrandingContainer>
      );
    }

    render() {
      return (
        <Body>
          <ContentContainer>
            <TrippyBackground>
            </TrippyBackground>
            <Sidebar>
              <BlockLink to="/">
                <HeaderImg src={Logo} />
              </BlockLink>
              {this.renderContent()}
              <div style={{ flex: '1' }} />
              {isFeatureFlagEnabled('flowmasters') ? this.brandingFlowmasters() : this.renderBranding()}
            </Sidebar>
          </ContentContainer>
        </Body>
      );
    }
  });
}

@sidebarTheme
export default class Login extends BaseLogin {
  renderForm() {
    return (
      <React.Fragment>
        {super.renderForm()}
        <Divider horizontal>{t('user.login.or')}</Divider>
        <Button
          fluid
          primary
          data-test-work-station-login-button
          content={t('user.login.workStationStartButton')}
          as={Link}
          to="/production/login"
        />
        <Divider horizontal>{t('user.login.or')}</Divider>
        <Button
          fluid
          primary
          data-test-operator-login-button
          content={t('user.login.operatorStartButton')}
          as={Link}
          to="/operator/login"
        />
      </React.Fragment>
    )
  }
}
