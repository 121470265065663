import React from 'react'
import DefaultView from './Default';
import { observer } from 'mobx-react';
import { computed, observable, action } from 'mobx';
import { AdminOverviewSetting } from 'helpers/types';
import { ProductionRequest, ProductionRequestStore } from 'store/ProductionRequest';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import PerformMultiAction, { MULTI_ACTION_RELATIONS } from '../../../component/Progress/MultiAction';
import { MultiActionPerformModal } from '../../../container/Perform/MultiActionPerformModal';
import { Operator } from 'store/Operator';
import OperatorModal from '../../../container/ProductionRequest/ProductionPerform/OperatorModal';
import RadanImportModal from '../../../container/WorkStation/RadanImportModal';
import { RadanIntegrationStore } from 'store/Workstation/RadanIntegration';
import { t } from 'i18n'
import ToggleFinished from 'component/ProductionRequest/ToggleFinished'

const NESTING_STEP_TYPES = [
    'Export',
    'Import',
]


@observer
export default class ExportView extends DefaultView {

    @observable selectedProductionRequests = new ProductionRequestStore({
        relations: MULTI_ACTION_RELATIONS
    });

    /**
     * The current Action to be performed
     */
    @observable action: JSX.Element | null = null;

    @observable operator: Operator | null = null;

    @observable radanIntegrationStore = new RadanIntegrationStore({
        relations: ['integration'],
    });

    constructor(props) {
        super(props)
        const givenOperator = props.viewStore?.currentUser?.operator
        if (!givenOperator.isNew) {
            this.operator = givenOperator
        }
    }

    componentDidMount() {
        super.componentDidMount()

        this.radanIntegrationStore.fetch()
    }

    /**
     * Adds or deletes a production request from the
     * @param data
     * @param productionRequest
     */
    onToggleProductionRequest = (data: CheckboxProps, productionRequest: ProductionRequest) => {
        if (!productionRequest.id) {
            return;
        }
        if (!this.selectedProductionRequests.get(productionRequest.id)) {
            this.selectedProductionRequests.add(productionRequest.toJS())
        } else {
            this.selectedProductionRequests.removeById(productionRequest.id)
        }
    }

    // @ts-ignore
    @computed get settings(): AdminOverviewSetting<ProductionRequest>[] {
        // @ts-ignore
        const superSettings: AdminOverviewSetting<ProductionRequest>[] = this.baseSettings();

        // @ts-ignore
        const newSetting: AdminOverviewSetting<ProductionRequest>[] = [
            {
                // @ts-ignore
                label: (
                    <>
                      <Checkbox
                        data-test-bulk-select
                        // @ts-ignore
                        onChange={action((e, { checked }) => {
                            this.store.models.forEach(productionRequest => {
                                if (checked) {
                                    if (!this.selectedProductionRequests.get(productionRequest.id)) {
                                        this.selectedProductionRequests.add(productionRequest.toJS())
                                    }
                                } else {
                                    this.selectedProductionRequests.removeById(productionRequest.id)
                                }
                            })
                        })}
                      />
                    </>
                  ),
                attr: (productionRequest: ProductionRequest) => (
                    <Checkbox
                        data-test-select-production-request={productionRequest.id}
                        onChange={(event, data) => {
                            this.onToggleProductionRequest(data, productionRequest)
                        }}
                        checked={(productionRequest.id !== null && this.selectedProductionRequests.get(productionRequest.id) !== undefined) ? true : false}
                    />
                )
            }
        ];

        return newSetting.concat(superSettings);
    }

    // @ts-ignore
    @computed get filters() {
        // @ts-ignore
        const superFilters = [...this.baseFilters()]

        const nestingStepTypeFilter = {
            type: 'multiPick',
            label: t('progress.nestingStepType.label'),
            name: '.current_nesting_step_type:in',
            options: NESTING_STEP_TYPES.map(type => ({
                value: type,
                text: type,
            }))
        }

        superFilters.splice(4, 0, nestingStepTypeFilter)
        return superFilters
    }

    buttons = [
        productionRequest => (
            <ToggleFinished
                productionRequest={productionRequest}
                afterSave={() => this.fetch()}
                {...this.itemButtonProps}
            />
        ),
    ]

    renderOverviewTable() {
        return (
            <>
                {super.renderOverviewTable()}
                {!this.operator && this.renderOperatorModal()}
                <MultiActionPerformModal
                    action={this.action}
                    onClose={this.clearAction.bind(this)}
                />
            </>
        )
    }

    renderOperatorModal = () => {
        return <OperatorModal
            onSelect={(scannedOperator: Operator) => {
                this.operator = scannedOperator
            }}
            onClose={() => window.location.href = '../../production-request/overview'} // If cancel badge scan => go back to work progress view
        />
    }

    afterActionPerformed = async () => {
        await this.fetch()


    }

    clearAction = () => {
        this.action = null;
    }

    toolbar = [
        () => {
            if (!this.operator) {
                return (<></>);
            }

            // Show for all Radan integrations the import button.
            return this.radanIntegrationStore.map(radanIntegration => (
                <RadanImportModal
                    radanIntegration={radanIntegration}
                    operator={this.operator}
                    afterUpload={this.afterActionPerformed}
                />
            ))
        },
        () => {
            if (!this.operator) {
                return (<></>)
            }

            return (
                <PerformMultiAction
                    selectedProductionRequests={this.selectedProductionRequests}
                    workStation={this.workStation}
                    onSelectMultiAction={(action) => {
                        this.action = action
                    }}
                    operator={this.operator}
                    afterPerform={this.afterActionPerformed}
                />
            )
        }
    ]
}
