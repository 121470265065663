import React, { Component } from 'react'
import { ItemButton } from 'component/AdminOverview'
import { ProductionRequest } from 'store/ProductionRequest'
import { Form, Modal } from 'semantic-ui-react'
import { t } from 'i18n'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { CancelButton, SaveButton } from 'spider/semantic-ui/Button'
import RightDivider from 'spider/component/RightDivider'
import { TargetTextArea } from 'spider/semantic-ui/Target'
import { showSaveNotification } from 'helpers/notification'

interface ToggleFinishedButtonProps {
    productionRequest: ProductionRequest;
    afterSave?: () => void;
}

@observer
export default class ToggleFinished extends Component<ToggleFinishedButtonProps> {
    @observable isOpen = false
    open = () => this.isOpen = true
    close = () => this.isOpen = false

    toggleFinishedStatus = async () => {
        const { productionRequest, afterSave } = this.props

        await productionRequest.toggleFinishedStatus()
        showSaveNotification()
        afterSave?.()
    }
    render() {
        const { productionRequest, ...props } = this.props

        return (
            <Modal data-test-finish-modal
                onClose={this.close}
                trigger={(
                    <ItemButton data-test-finish-order-button={productionRequest.id}
                        icon={productionRequest.finished ? 'redo' : 'check'}
                        label={productionRequest.finished ? t('tooltips.unfinish') : t('tooltips.finish')}
                        onClick={productionRequest.finished ? this.toggleFinishedStatus : this.open}
                        {...props}
                    />
                )}
            >
                <Modal.Header>{t('productionRequest.finishModal.header')}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <TargetTextArea required autoFocus
                            target={productionRequest}
                            name="manualFinishedReason"
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <CancelButton onClick={this.close} />
                    <RightDivider />
                    <SaveButton primary
                        content={t('tooltips.finish')}
                        onClick={this.toggleFinishedStatus} />
                </Modal.Actions>
            </Modal>
        )
    }
}
