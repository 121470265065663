import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Integration } from './Integration'

export class ExactIntegration extends Model {
  static backendResourceName = 'exact_integration'
  static omitFields = ['active']

  @observable id = null
  @observable country = 'nl'
  @observable division = null
  @observable active = false
  @observable webhooks = true
  @observable clientId = null
  @observable clientSecret = null

  @observable webhookBacklogCount = 0

  /** START Topic flags */
  @observable address = true
  @observable item = true
  @observable itemExtraField = true
  @observable account = true
  @observable salesOrder = true
  @observable purchaseOrder = true
  @observable opportunity = true
  @observable shippingMethod = true
  @observable document = true
  @observable documentAttachment = true

  @observable project = false
  @observable billOfMaterial = false
  @observable billOfMaterialItem = false
  @observable shopOrder = false
  @observable shopOrderReceipt = false
  @observable shopOrderReversal = false
  @observable salesReturn = false

  @observable byproductReceipt = false
  @observable byproductReversal = false

  @observable stockTopic = false

  @observable goodsReceipt = false
  @observable goodsDelivery = false
  @observable stockCount = false
  @observable stockCountReversal = false
  @observable warehouseTransfer = false
  @observable stockSerialNumber = true
  @observable dropShipment = false
  @observable workcenter = false
  /** END Topic flags */

  /** START Webhooks flags */
  @observable salesOrderWebhookTopic = true
  /** END Topic flags */

  @observable apiRateLimitLimit = 0
  @observable apiRateLimitRemaining = 0
  @observable apiRateLimitReset = 0

  relations() {
    return {
      integration: Integration,
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    delete res.sales_order_webhook
    return res
  }
}

export class ExactIntegrationStore extends Store {
  static backendResourceName = 'exact_integration'
  Model = ExactIntegration
}
