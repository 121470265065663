import React from 'react'
import { computed, reaction } from 'mobx'
import BaseAdminOverview, { getModelName } from 'spider/semantic-ui/Admin/Overview';
import Toolbar from 'component/Toolbar'
import styled from 'styled-components'
import { Metafilters } from 'component/Metafields'
import { Icon } from 'semantic-ui-react';
import { camelToSnake } from 'spider/helpers';
import { t } from 'i18n';

export const EmptyMessageContainer = styled.p`
  text-align: center;
  margin-top: 2rem;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
`

function getPrefix(prefix) {
  if (prefix === '') {
    return ''
  } else {
    return `${prefix} - `
  }
}


const DeleteIconContainer = styled.div`
    margin-top: 52px;
    margin-bottom: -52px;
    margin-right: 0px;
    margin-left: 268px;
    cursor: pointer;
`;

const DeleteIconHackContainer = styled.div`

  .daycy {
    margin-right: 35px !important;
  }
  margin-bottom: 10px;
`;

export default class AdminOverview extends BaseAdminOverview {
  Toolbar = Toolbar

  // So we do this with a reaction instead of a computed value
  // because the base admin overview already has tabTitlePrefix as an
  // attribute and thus we sadly cannot override it with a getter
  tabTitlePrefix = getPrefix(window.viewStore.tabTitlePrefix)

  componentDidMount() {
    super.componentDidMount()
    this.tabTitlePrefixReaction = reaction(
      () => window.viewStore.tabTitlePrefix,
      (tabTitlePrefix) => {
        this.tabTitlePrefix = getPrefix(tabTitlePrefix)
        this.forceUpdate()
      }
    )
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    this.tabTitlePrefixReaction()
  }

  emptyMessage = null
  emptyMessageSearch = null

  itemButtonProps = {
    primary: false,
  }

  @computed get empty() {
    return (
      !this.store.isLoading &&
      (this.store.totalPages === 0 ||
        (this.store.totalPages === 1 && this.store.currentPage === 1 && this.store.length === 0))
    )
  }

  renderOverviewTable() {
    return this.empty && this.emptyMessage ? (
      <EmptyMessageContainer>{this.emptyMessage}</EmptyMessageContainer>
    ) : (
      super.renderOverviewTable()
    )
  }

  renderPaginationControls() {
    return this.empty && this.emptyMessage ? null : super.renderPaginationControls()
  }

  /**
   * Overwritten because of bug, with this line as fix:
   * model = new model().relations()[field];
   *
   * Upgrading spider would fix this, but I don't dare to do this right now. This override can be removed when spider is
   * updated.
   */
  attrSetting(setting) {
        const path = setting.attr.split('.');
        const field = path.pop();

        let model = this.store.Model;
        let label, sortKey;
        if (path.length > 0) {
            let prevModel = null;
            sortKey = '';
            let subPath = '';
            // eslint-disable-next-line
            for (const field of path) {
                if (sortKey !== '') {
                    sortKey += '.';
                }
                if (subPath !== '') {
                    subPath += '.';
                }
                sortKey += camelToSnake(field);
                subPath += field;
                prevModel = model;
                model = new model().relations()[field];
                if (model === undefined) {
                    throw new Error(`Not an existing relation: ${subPath}`);
                }
            }
            label = t(`${getModelName(prevModel)}.field.${path[path.length - 1]}.label`);
            sortKey += '.' + camelToSnake(field);

            const rel = path.join('.');
            if (!this.store.__activeRelations.some((activeRel) => (
                activeRel === rel || activeRel.startsWith(`${rel}.`)
            ))) {
                throw new Error(`Not an active relation: ${path.join('.')}`);
            }
        } else {
            label = t(`${getModelName(model)}.field.${field}.label`);
            sortKey = camelToSnake(field);
        }

        return {
            ...setting,
            label: setting.label || label,
            sortKey: setting.sortKey || sortKey,
            attr: (field !== 'id' || ((model.idPrefix === undefined || model.idPrefix === '') && (model.idIcon === undefined || model.idIcon === ''))) ? (obj) => {
                // eslint-disable-next-line
                for (const field of path) {
                    obj = obj[field];
                }
                return obj[field];
            } : (obj) => {
                // eslint-disable-next-line
                for (const field of path) {
                    obj = obj[field];
                }
                return obj.id && obj.getLink();
            },
        };
    }

  renderFilter(filter, i) {
    // scope none does not need to filter on day/week/month
    if (window.viewStore.progressScope === 'none' && filter.type === 'progress') {
      return
    }
    if (typeof filter === 'function') {
      filter = filter();
    }
    if (filter.type === 'progress') {
      filter.type = {
        month: 'month',
        week: 'week',
        day: 'date',
      }[window.viewStore.progressScope];
    }
    if (filter.type === 'metafields') {
      const withDivider = filter.withDivider
      filter = {
        type: 'custom',
        callback: () => (
          <Metafilters
            store={this.store}
            fetch={this.fetch.bind(this)}
            debouncedFetch={this.debouncedFetch}
            withDivider={withDivider}
          />
        ),
      }
    }

    const res =  super.renderFilter(filter, i)
    if (['month', 'week', 'date'].includes(filter.type)) {
      return (
        <>
          <DeleteIconHackContainer>
            <DeleteIconContainer onClick={() => {

              delete this.store.params[filter.name]
              this.fetch()
            }
            } >
              <Icon name="delete" />
            </DeleteIconContainer>
            {res}
          </DeleteIconHackContainer>


        </>
      )
    }

    return res;
  }

  removeItem(item) {
    if (item?.__store?.Model?.backendResourceName) {
      // overwrite this part from spider, so we can fit it in "deleteConfirmation: 'Are you sure you want to delete {{name}} ?',"
      if (window.confirm(t('form.deleteConfirmation', { name: `${item?.__store?.Model?.backendResourceName}(${item?._id})` }))) {
          return item.delete();
      } else {
          return Promise.reject();
      }
    } else {
      return super.removeItem(item);
    }
}
}

export * from 'spider/semantic-ui/Admin/Overview'
