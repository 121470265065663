import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { BatchStore } from 'store/Batch'
import { ArticleTypeStore } from 'store/ArticleType'
import { TargetTextInput } from 'spider/semantic-ui/Target'
import RightDivider from 'spider/component/RightDivider'

@observer
export default class FindBatchOrArticleTypeModal extends Component {
  static propTypes = {
    onFound: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  @observable value = ''
  @observable error = null
  @observable batches = [{}]

  @observable isOpen = false
  open = () => (this.isOpen = true)
  close = () => (this.isOpen = false)
  onUnmount = () => (this.value = '')

  onChange = (value) => {
    this.value = value
  }

  onSubmit = () => {
    const { onFound } = this.props
    const batchStore = new BatchStore({
      params: {
        '.serial_number': this.value,
      },
    })
    const articleTypeStore = new ArticleTypeStore({
      params: {
        '.exact_item.code': this.value,
      },
    })

    this.error = null

    Promise.all([batchStore.fetch(), articleTypeStore.fetch()])
      .then(() => {
        if (batchStore.length === 0 && articleTypeStore.length === 0) {
          this.error = t('workStation.production.batchModal.error.notFound')
          return
        }

        if (batchStore.length === 1) {
          onFound(batchStore.at(0))
          this.close()
        }

        if (articleTypeStore.length === 1) {
          onFound(articleTypeStore.at(0))
          this.close()
        }
      })
      .catch(() => {})
  }

  render() {
    return (
      <Modal
        data-test-show-find-batch-modal
        open={this.isOpen}
        onClose={this.close}
        onUnmount={this.onUnmount}
        size="tiny"
        trigger={
          <Button
            primary
            data-test-show-find-batch-modal-button
            content={t('batch.batchInfoModal.trigger')}
            icon="square-info"
            labelPosition="left"
            onClick={this.open}
          />
        }
      >
        <Modal.Header>{t('workStation.production.batchModal.title')}</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.onSubmit}>
            <TargetTextInput
              autoFocus
              label={t('batch.field.serialNumber.label')}
              name="serialNumber"
              value={this.value}
              onChange={this.onChange}
              errors={this.error === null ? [] : [this.error]}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <RightDivider />
          <Button
            primary
            data-test-confirm-button
            icon="check"
            labelPosition="left"
            content={t('workStation.production.batchModal.confirmButton')}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
