import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DownloadButton } from 'spider/semantic-ui/Button'
import PropTypes from 'prop-types';
import { Integration } from 'store/Integration';
import { Operator } from 'store/Operator'
import { observable } from 'mobx';

@observer
export default class RadanImportStep extends Component {
  static propTypes = {
    integration: PropTypes.instanceOf(Integration).isRequired,
    operator: PropTypes.instanceOf(Operator).isRequired,
  }

  state = {
    selectedFile: null,
  }

  @observable integration;

  fileInputRef = React.createRef();

  componentDidMount() {
    const { integration } = this.props;
    this.integration = new Integration({ id: integration.id }, {
      relations: ['radanIntegration']
    });
    this.integration.fetch()

  }

  uploadXml = async () => {
    const { operator } = this.props
    const formData = new FormData()
    formData.append('xml_upload', this.state.selectedFile, this.state.selectedFile.name)

    // Note that Radan Integration is currently stateless, meaning that we do not have to fetch extra data right now
    const radanIntegration = this.integration.radanIntegration;
    await radanIntegration.importXml(operator, formData)
  }

  fileChange = e => {
    const file = e.target.files[0]
    this.setState({ selectedFile: file })
  };


  render() {
    return (
      <>
        <DownloadButton
          compact
          primary
          data-test-import-radan-button
          icon="upload" labelPosition="right"
          content={() => this.state.selectedFile ? this.state.selectedFile.name : t('radan.import.choose')}
          onClick={() => this.fileInputRef.current.click()}
        />
        <input
          ref={this.fileInputRef}
          data-test-import-radan-input
          type="file"
          hidden
          onChange={this.fileChange}
        />
        <DownloadButton
          compact
          primary
          data-test-upload-button
          icon="send"
          labelPosition="right"
          content={t('radan.import.upload')}
          onClick={this.uploadXml}
        />
      </>
    )
  }
}
