import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { PurchaseOrder } from './PurchaseOrder'
import { ProductionRequest } from './ProductionRequest'
import { InShipmentLineStore } from './InShipmentLine'

export class InShipment extends Model {
  static backendResourceName = 'in_shipment'
  static idPrefix = 'RE'
  static idColor = 'blue'
  static idIcon = 'move_to_inbox_white'

  getUrl() {
    return `/warehouse/inbound/order/${this.purchaseOrder.id}/shipment/${this.id}?.id=${this.purchaseOrder.id}`;
  }

  getMiniTag() {
    return window.viewStore.tracyTagSize === 'mini'
  }

  @observable id = null

  relations() {
    return {
      purchaseOrder: PurchaseOrder,
      lines: InShipmentLineStore,
      productionRequest: ProductionRequest,
    }
  }
}

export class InShipmentStore extends Store {
  static backendResourceName = 'in_shipment'

  Model = InShipment

  totalQuantityForPurchaseOrderLine(purchaseOrderLine, purchaseOrder) {
    purchaseOrder = purchaseOrder ?? purchaseOrderLine.purchaseOrder
    let totalQuantity = this.models.reduce(
      (result, inShipment) =>
        result +
        inShipment.lines
          .filter((l) => purchaseOrder.lines.get(l.purchaseOrderLine.id).articleType.id === purchaseOrderLine.articleType.id)
          .reduce((result2, line) => result2 + Math.max(line.productionRequest.quantity, line.productionRequest.quantityDone), 0),
      0
    )

    // eslint-disable-next-line
    for (const line of purchaseOrder.lines.models) {
      if (line.articleType.id !== purchaseOrderLine.articleType.id) {
        continue
      }
      const lineQuantity = Math.min(totalQuantity, line.quantity)
      if (line.id === purchaseOrderLine.id) {
        return lineQuantity
      }
      totalQuantity -= lineQuantity
    }
  }

  availableQuantityForPurchaseOrderLine(purchaseOrderLine, purchaseOrder) {
    const added = this.totalQuantityForPurchaseOrderLine(purchaseOrderLine, purchaseOrder)

    return purchaseOrderLine.quantity - added
  }
}
