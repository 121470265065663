import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Form, Button, Popup } from 'semantic-ui-react'
import { TargetTextInput, TargetSelect } from 'spider/semantic-ui/Target'
import { ShippingUnitStore } from 'store/ShippingUnit'
import { IconButton } from 'spider/semantic-ui/Button'
import { TargetNumberInput } from '../../../../spider/semantic-ui/Target'
import styled from 'styled-components'
import { OutShipmentLineStore } from '../../../../store/OutShipmentLine'
import { omit, sortBy } from 'lodash';
import { PickLinesLinkContainer, IconButtonContainer, TextPickLinesHoverContainer, PickLinesAmountContainer, PickLinesHoverContainer } from './helper'
import { CARRIER_STEP_SMALL_SCREEN_WIDTH } from '../Carrier'
import Decimal from 'decimal.js'

const DeleteRowButtonContainer = styled.div`
    padding-top: ${({ i }) => i === 0 ? '23px' : '8px'}; 
    padding-bottom: 0;
`

const SmallTargetSelect = styled(TargetSelect)`
  > .ui.dropdown {
    min-width: 0 !important;
  }
`

const CustomForm = styled(Form)`
  .fields > .field {
    padding-left: 0 !important;
    padding-right: 0.5em !important;
    padding-top: 0 !important;
  }
`

export const calculateVolume = (quantity, articleType) => {
    // T42221: calculate the volume in m3 for one pickLine
    //   V: articleType.volume
    //   F: articleType.volumeFactor
    //   Q: quantity
    // returns: `(V * (F/100) * (Q - 1)) + V`
    if (!articleType || quantity === null || articleType.volume === null || articleType.volumeFactor  === null ) {
        return null
    }

    const volume = Decimal(articleType.volume)
    const volumeFactor = Decimal(articleType.volumeFactor)
    return (                                    // (
            volume.mul(volumeFactor.div(100))   //   V * (V/100)
            .mul((Decimal(quantity).sub(1)))    //   * (Q - 1)
        )                                       // )
        .plus(volume)                           // + V
}

@observer
export default class ColliTable extends Component {
    static propTypes = {
        colliTableId: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        isFrozenField: PropTypes.func.isRequired,
        shippingLabelCodes: PropTypes.object.isRequired,
        colloStartNr: PropTypes.number.isRequired,
        shippingUnits: PropTypes.instanceOf(ShippingUnitStore),
        selectedPickLines: PropTypes.instanceOf(Set).isRequired,
        emptyPickLines: PropTypes.func.isRequired,
        pickLinesStore: PropTypes.instanceOf(OutShipmentLineStore).isRequired,
    }

    static defaultProps = {
        shippingUnits: null
    }

    static maxNumberOfColli = 15;

    @observable numberOfColli = 1;

    getNumberOfCollie() {
        return this.numberOfColli;
    }

    updateNumberOfColli(newValue) {
        newValue = parseInt(newValue);
        if (newValue <= ColliTable.maxNumberOfColli && 0 <= newValue) {
            this.numberOfColli = newValue;
            this.forceUpdate();
            this.updateMainData();
        }
    }

    updateRefernece(reference, i) {
        this.references[i] = reference;
        this.updateMainData();
    }

    updateMainData() {
        const { onChange } = this.props;
        let packageData, newData;
        packageData = Array(this.numberOfColli);
        for (let i = 0; i < this.getNumberOfCollie(); i++) {
            packageData[i] = {
                'length': this.getGridData(i, 0),
                'width': this.getGridData(i, 1),
                'height': this.getGridData(i, 2),
                'weigth': this.getGridData(i, 3),
                'volume': this.getGridData(i, 6) ? this.getGridData(i, 6) : 0,
                'shippingMethod': this.getGridData(i, 4),
                'amount': this.getGridData(i, 5) ? this.getGridData(i, 5) : 1,
                'reference': this.references[i],
                'pickLines': this.pickLines[i],
            }
        }
        newData = {
            'numberOfColli': this.getNumberOfCollie(),
            'packageData': packageData,
        };
        onChange(newData);
    }

    //Data of grid
    @observable gridData;
    @observable references = {};
    @observable pickLines = {};
    componentDidMount() {
        this.gridData = Array.from(Array(ColliTable.maxNumberOfColli), () => new Array(7));
        for (let i = 0; i < this.gridData.length; i++) {
            for (let j = 0; j < this.gridData[i].length; j++) {
                this.gridData[i][j] = 0;
            }
        }
        for (let i = 0; i < ColliTable.maxNumberOfColli; i++) {
            this.references[i] = '';
            this.pickLines[i] = {};
        }
        //this.updateMainData()

    }

    updateGridData(x, y, newValue) {
        const { shippingUnits } = this.props
        if (y !== 6) { // 6 is for the volume, which is not an integer
            newValue = parseInt(newValue);
        }
        if (isNaN(newValue)) {
            return
        }

        if (y === 6) {
            this.updateVolumeGridData(x);
        }
        if (shippingUnits?.models?.length > 0 && !shippingUnits.models.some((unit) => unit.id === this.gridData[x][4])) {
            this.gridData[x][0] = shippingUnits.models[0].length;
            this.gridData[x][1] = shippingUnits.models[0].width;
            this.gridData[x][2] = shippingUnits.models[0].height;
            this.gridData[x][3] = shippingUnits.models[0].weight;
            this.gridData[x][4] = shippingUnits.models[0].id;
        } else if (((shippingUnits?.models?.length === 0) || (shippingUnits?.length === 0)) && this.gridData[x][4] !== 0 ) {
            this.gridData[x][0] = 0;
            this.gridData[x][1] = 0;
            this.gridData[x][2] = 0;
            this.gridData[x][3] = 0;
            this.gridData[x][4] = 0;
        }

        this.gridData[x][y] = newValue;

        // If we changed length, width or height, recalculate volume!
        if ([0,1,2].includes(y)){
            this.updateVolumeGridData(x);
        }
        this.forceUpdate();
        this.updateMainData()
    }

    updateVolumeGridData(x) {
        // update volume field (T42221)
        this.gridData[x][6] = this.calculateRowVolumePickLines(x);
    }

    getGridData(x, y) {
        const { shippingUnits } = this.props
        if (this.gridData == null) {
            return 0;
        }

        if (shippingUnits?.models?.length > 0 && !shippingUnits.models.some((unit) => unit.id === this.gridData[x][4])) {
            switch(y){
                case 0:
                    return shippingUnits.models[0].length;
                case 1:
                    return shippingUnits.models[0].width;
                case 2:
                    return shippingUnits.models[0].height;
                case 3:
                    return shippingUnits.models[0].weight
                case 4:
                    return shippingUnits.models[0].id;
                case 6:
                    return this.calculateRowVolumePickLines(x)
                default:
                    break;
            }
        } else if (((shippingUnits?.models?.length === 0) || (shippingUnits?.length === 0)) && this.gridData[x][4] !== 0 && y<=4 ) {
            return 0
        }

        return this.gridData[x][y];
    }

    calculateRowVolumeBox(quantity, x) {
        // calculate volume base on: length * width * height
        const length = Decimal(this.gridData[x][0]).div(100)
        const width = Decimal(this.gridData[x][1]).div(100)
        const height = Decimal(this.gridData[x][2]).div(100)
        return Decimal(quantity).mul(length).mul(width).mul(height)
    }

    calculateRowVolumePickLines(x) {
        // T42221: calculate the total volume in m3, which is the sum off the volume of all related picklines
        if (Object.keys(this.pickLines[x]).length > 0) {
            return Object.keys(this.pickLines[x]).map((key, index) => key).reduce(
                (sum, key) => sum.plus(this.calculateVolumePickLine(x, key)),
                Decimal(0)
            )
        } else {
            // by default, calculate the volume as a box when there are now pick lines
            return this.calculateRowVolumeBox(1, x)
        }

    }

    calculateVolumePickLine(i, key) {
        // calculates volume in m3, uses l*w*h if no volume and volume_factor available
        const { pickLinesStore } = this.props

        const quantity = this.pickLines[i][key]
        const articleType = pickLinesStore.get(key)?.salesOrderLine?.articleType

        if (articleType?.volume === null || articleType?.volumeFactor === null) {
            return this.calculateRowVolumeBox(quantity, i)
        } else {
            return calculateVolume(quantity, articleType)
        }
    }

    calculateTotalWeight() {
        let totalweight = 0;
        for (let i = 0; i < this.getNumberOfCollie(); i++) {
            if (this.getGridData(i, 3) === 0) {
                return '-'
            }
            totalweight += parseInt(this.getGridData(i, 3));
        }
        return totalweight;
    }

    getShippingMethods() {
        // T42657 should be ordered by number
        let shippingMethods = sortBy(this.props.shippingUnits?.models, 'number').map((item) => {
            return { name: item.description, ...item }
        })

        return shippingMethods ?? [];
    }

    updateValuesWithShippingMethod(i, shippingMethod) {
        this.updateGridData(i,0,shippingMethod.length)
        this.updateGridData(i,1,shippingMethod.width)
        this.updateGridData(i,2,shippingMethod.height)
        this.updateGridData(i,3,shippingMethod.weight)

        this.updateMainData();
    }

    removeRow(row){
        for (let i = row; i < ColliTable.maxNumberOfColli - 1; i++) {
            this.gridData[i] = this.gridData[i+1]
        }
        this.gridData[ColliTable.maxNumberOfColli - 1] = new Array(6);
        this.updateMainData();
        this.numberOfColli--;
    }

    renderPicklines(i){
        const { pickLinesStore } = this.props

        if (this.pickLines === null || this.pickLines[i] === null) {
            return <></>
        }

        return Object.keys(this.pickLines[i]).map((key, index) => (
            <Popup
                trigger={
                    <PickLinesAmountContainer
                        data-test-pick-order-line-amount-container={key}
                    >
                        {this.pickLines[i][key]}x
                        {pickLinesStore.get(key).salesOrderLine.articleType.getLabel()}
                    </PickLinesAmountContainer>
                }
                flowing
                hoverable
                on='click'
                pinned
            >
                <PickLinesHoverContainer>
                    <IconButtonContainer>
                        <IconButton
                            size='huge'
                            fluid
                            data-test-click-min-button
                            name="minus"
                            onClick={()=>{
                                this.pickLines[i][key]--
                                if(this.pickLines[i][key] <= 0){
                                    // Remove from this.picklines
                                    this.pickLines[i] = omit(this.pickLines[i], [key])
                                }
                                this.updateVolumeGridData(i)
                                this.updateMainData();
                            }}
                        />
                    </IconButtonContainer>
                    <TextPickLinesHoverContainer>
                        {this.pickLines[i][key]}
                    </TextPickLinesHoverContainer>
                    <IconButtonContainer>
                        <IconButton
                            size='huge'
                            fluid
                            data-test-click-plus-button
                            name="plus"
                            onClick={()=>{
                                this.pickLines[i][key]++
                                this.updateVolumeGridData(i)
                                this.updateMainData();
                            }}
                        />
                    </IconButtonContainer>
                </PickLinesHoverContainer>
            </Popup>
        ))
    }

    getTotalSelectedPickOrderLine(key){
        let totalSelectedPickorderLine = 0
        for (let j = 0; j < ColliTable.maxNumberOfColli; j++) {
            if(key in this.pickLines[j]){
                totalSelectedPickorderLine += this.pickLines[j][key]
            }
        }
        return totalSelectedPickorderLine
    }

    linkPickOrderLine(i){
        const { selectedPickLines, emptyPickLines, pickLinesStore } = this.props;
        // Add all selected pick lines
        // eslint-disable-next-line
        for (const pickLine of selectedPickLines) {
            if (pickLine in this.pickLines[i]) {
                this.pickLines[i][pickLine] = this.pickLines[i][pickLine] + 1;
            } else {
                let totalSelectedPickorderLine = this.getTotalSelectedPickOrderLine(pickLine);
                this.pickLines[i][pickLine] = pickLinesStore.get(pickLine).productionRequest.quantity.d[0] - totalSelectedPickorderLine;
            }
            let prefix = this.references[i] === '' ? '': ' |'
            this.references[i] += `${prefix} ${pickLinesStore.get(pickLine).salesOrderLine.articleType.code}`
            this.updateVolumeGridData(i)
        }

        // clear the pick lines
        emptyPickLines();

        this.updateMainData();
    }

    render() {
        const { colliTableId, isFrozenField } = this.props;
        return (
            <>
                <Form data-test-grid={colliTableId} striped >
                    {[...Array(this.getNumberOfCollie())].map((x, i) => {
                        // T43240: on small screens we need to divide into 2 rows
                        const UnitInput = <SmallTargetSelect data-test-unitTable-selector
                            value={this.getGridData(i,4) ? this.getGridData(i,4) : null }
                            label={i===0 ? t('workStation.production.performModal.carrierStep.pickShippingUnit') : null}
                            disabled={isFrozenField()}
                            placeholder={t('workStation.production.performModal.carrierStep.pickShippingUnit')}
                            options={(this.getShippingMethods()).map((type) => ({
                                value: type.id,
                                text: type.name,
                            }))}
                            onChange={(newValue) => {
                                this.updateGridData(i, 4, newValue);
                                this.updateValuesWithShippingMethod(i,this.getShippingMethods().filter((x)=> x.id === newValue)[0])
                            }}
                        />

                        const firstFormRow = <>
                            <TargetNumberInput
                                width={5}
                                data-test-cel={[i, 5]}
                                label={i===0 ? t('workStation.production.performModal.carrierStep.amount') : null}
                                value={this.getGridData(i, 5) !== 0 ? this.getGridData(i, 5).toString() : '1'}
                                onChange={(value) => { this.updateGridData(i, 5, parseInt(value)) }}
                                disabled={isFrozenField()}
                            />
                            <TargetNumberInput
                                data-test-cel={[i, 4]}
                                width={5}
                                label={i===0 ? t('workStation.production.performModal.carrierStep.weigth') : null}
                                value={this.getGridData(i, 3) !== 0 ? this.getGridData(i, 3).toString() : ''}
                                onChange={(value) => { this.updateGridData(i, 3, parseInt(value)) }}
                                disabled={isFrozenField()}
                                suffix='kg'
                            />
                        </>
                        const secondFormRow = <>
                            <TargetNumberInput
                                data-test-cel={[i, 0]}
                                width={5}
                                label={i===0 ? t('workStation.production.performModal.carrierStep.length') : null}
                                value={this.getGridData(i, 0) !== 0 ? this.getGridData(i, 0).toString() : ''}
                                onChange={(value) => { this.updateGridData(i, 0, value) }}
                                disabled={isFrozenField()}
                                suffix='cm'
                            />
                            <TargetNumberInput
                                data-test-cel={[i, 1]}
                                width={5}
                                label={i===0 ? t('workStation.production.performModal.carrierStep.width') : null}
                                value={this.getGridData(i, 1) !== 0 ? this.getGridData(i, 1).toString() : ''}
                                onChange={(value) => { this.updateGridData(i, 1, value) }}
                                disabled={isFrozenField()}
                                suffix='cm'
                            />
                            <TargetNumberInput
                                data-test-cel={[i, 2]}
                                width={5}
                                label={i===0 ? t('workStation.production.performModal.carrierStep.height') : null}
                                value={this.getGridData(i, 2) !== 0 ? this.getGridData(i, 2).toString() : ''}
                                onChange={(value) => { this.updateGridData(i, 2, value) }}
                                disabled={isFrozenField()}
                                suffix='cm'
                            />
                            <TargetTextInput
                                data-test-cel={[i, 3]}
                                width={5}
                                label={i===0 ? t('workStation.production.performModal.carrierStep.volume') : null}
                                onChange={(value) => { this.updateGridData(i, 6, value) }}
                                disabled={isFrozenField()}
                                value={this.getGridData(i, 6) !== 0 ? this.getGridData(i, 6).toString() : ''}
                                suffix='m3'
                                info={// Show pickLine volume calculations; per row OR a general example
                                (this.pickLines[i] && Object.keys(this.pickLines[i]).length > 0) ?
                                    '= ' + Object.keys(this.pickLines[i]).map(
                                        (key, index) => {
                                            const articleType = this.props.pickLinesStore.get(key).salesOrderLine?.articleType
                                            const quantity = this.pickLines[i][key]
                                            if (articleType?.volume && articleType?.volumeFactor) {
                                                // [Volume * (VolumeFactor/100) * (Quantity - 1)] + Volume
                                                const volume = Decimal(articleType.volume)
                                                const volumeFactor = Decimal(articleType.volumeFactor)
                                                return `[${volume} * (${volumeFactor}/100) * (${quantity} - 1)] + ${volume}`
                                            } else {
                                                // griddata is in centimeter, convert to meter
                                                const length = Decimal(this.getGridData(i, 0)).div(100)
                                                const width = Decimal(this.getGridData(i, 1)).div(100)
                                                const height = Decimal(this.getGridData(i, 2)).div(100)
                                                return `${length} * ${width} * ${height} * ${quantity}`
                                            }
                                        }
                                    ).join(' + \n') + ' m3' : `${Decimal(this.getGridData(i, 0)).div(100)} * ${Decimal(this.getGridData(i, 1)).div(100)} * ${Decimal(this.getGridData(i, 2)).div(100)}`
                                    // `For each pickline [Volume * (VolumeFactor/100) * (Quantity - 1)] + Volume or Length * Width * Height * Quantity`
                                }
                            />
                        </>
                        const ReferenceInput = <TargetTextInput
                            label={i===0 ? t('workStation.production.performModal.carrierStep.reference') : null}
                            value={this.references[i]}
                            onChange={(val) => {
                                this.updateRefernece(val, i);
                            }}
                            disabled={isFrozenField()}
                        />
                        const deleteButton = <DeleteRowButtonContainer i={i}>
                            <Button
                                onClick={() => {this.removeRow(i)}}
                                icon='delete'
                                size='large'
                                label={null}
                            />
                        </DeleteRowButtonContainer>
                        return <>
                            <CustomForm onClick={()=>{this.linkPickOrderLine(i)}} widths="equal">
                                {window.innerWidth > CARRIER_STEP_SMALL_SCREEN_WIDTH ?
                                <Form.Group>
                                    <Form.Field width={15}>{UnitInput}</Form.Field >
                                    {firstFormRow}
                                    {secondFormRow} {ReferenceInput} {deleteButton}
                                </Form.Group> :
                                <>
                                    <Form.Group>
                                        <Form.Field width={15}>{UnitInput}</Form.Field>
                                        {firstFormRow}
                                    </Form.Group>
                                    <Form.Group>{secondFormRow} {ReferenceInput} {deleteButton}</Form.Group>
                                </>
                                }
                            </CustomForm>
                            <PickLinesLinkContainer>
                                <IconButton
                                    data-test-link-pick-lines={i}
                                    name="chain"
                                    onClick={()=>{this.linkPickOrderLine(i)}}
                                />
                                {this.pickLines[i] && Object.keys(this.pickLines[i]).length === 0 && (
                                    <>{t('workStation.production.performModal.carrierStep.link')}</>
                                )}
                                {this.pickLines[i] && Object.keys(this.pickLines[i]).length > 0 && this.renderPicklines(i)}
                            </PickLinesLinkContainer>
                        </>
                    })}
                </Form>
                <Button
                    data-test-add-shipment-line
                    primary
                    size='huge'
                    icon="plus"
                    labelPosition="left"
                    content="Add shipment line"
                    disabled={this.numberOfColli >= ColliTable.maxNumberOfColli}
                    onClick={()=>{
                        if (this.numberOfColli < ColliTable.maxNumberOfColli){
                            this.numberOfColli++;
                        }
                    }}
                />
            </>
        )
    }
}
