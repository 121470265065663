import { observable } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Operator } from './Operator'
import { User } from './User'
import { LeaveCalendar } from './LeaveCalendar'
import { LeaveSlotCommentStore } from './LeaveSlotComment'

export const STATUS_COLORS = {
  pending: '#fbbd08',
  approved: '#21ba45',
  rejected: '#db2828',
}
export const STATUS_ICONS = {
  pending: 'question',
  approved: 'check',
  rejected: 'delete',
}

export const STATUS_APPROVED = 'approved';
export const STATUS_PENDING = 'pending';
export const STATUS_REJECTED = 'rejected';

export const STATUSSES = [STATUS_PENDING, STATUS_APPROVED, STATUS_REJECTED]

export class LeaveSlot extends Model {
  static backendResourceName = 'leave_slot'

  static STATUSES = [STATUS_APPROVED, STATUS_PENDING, STATUS_REJECTED]
  static TYPES = ['vacation', 'rwh', 'special']

  @observable id = null
  @observable type = null
  @observable status = 'approved'
  @observable name = ''
  @observable startDate = null
  @observable endDate = null

  relations() {
    return {
      user: User,
      operator: Operator,
      leaveCalendar: LeaveCalendar,
      comments: LeaveSlotCommentStore,
    }
  }

  casts() {
    return {
      startDate: Casts.naiveDatetime,
      endDate: Casts.naiveDatetime,
    }
  }

  stats(capabilities = []) {
    const params = {}

    if (capabilities.length > 0) {
      params['capabilities'] = capabilities.join(',')
    }

    return this.api.get(`${this.url}stats/`, params)
  }
}

export class LeaveSlotStore extends Store {
  Model = LeaveSlot
  static backendResourceName = 'leave_slot'
}
