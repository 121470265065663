import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'
import { TargetBase, TargetNumberInput } from 'spider/semantic-ui/Target'

const DurationGroup = styled(Form.Group)`
  > .field {
      min-width: 0 !important;
  }
`

export default class TargetDuration extends TargetBase {
  static propTypes = {
    ...TargetBase.propTypes,
    value: PropTypes.number,
    disabled: PropTypes.bool,
    noSeconds: PropTypes.bool,
  }

  static defaultProps = {
    ...TargetBase.defaultProps,
    disabled: false,
    noSeconds: false,
  }

  fromTarget(value) {
    const { noSeconds } = this.props

    value = super.fromTarget(value)

    value = noSeconds ? {
      hours: Math.floor(value / 60),
      minutes: value % 60,
    } : {
      hours: Math.floor(value / 3600),
      minutes: Math.floor(value / 60) % 60,
      seconds: value % 60,
    }

    return value
  }

  toTarget(value) {
    const { noSeconds } = this.props

    value = noSeconds ? (
      value.hours * 60 +
      value.minutes
    ) : (
      value.hours * 3600 +
      value.minutes * 60 +
      value.seconds
    )

    value = super.toTarget(value)

    return value
  }

  renderContent({ includeSeconds, disabled, ...props }) {
    return (
      <DurationGroup widths="equal" {...props}>
        <TargetNumberInput noLabel
          value={this.value.hours.toString()}
          onChange={(hours) => this.onChange({ ...this.value, hours: parseInt(hours) })}
          disabled={disabled}
          suffix="h"
          placeholder={t('form.duration.hours')}
        />
        <TargetNumberInput noLabel
          value={this.value.minutes.toString()}
          onChange={(minutes) => this.onChange({ ...this.value, minutes: parseInt(minutes) })}
          disabled={disabled}
          suffix="m"
          placeholder={t('form.duration.minutes')}
        />
        {includeSeconds && (
          <TargetNumberInput noLabel
            value={this.value.seconds.toString()}
            onChange={(seconds) => this.onChange({ ...this.value, seconds: parseInt(seconds) })}
            disabled={disabled}
            suffix="s"
            placeholder={t('form.duration.seconds')}
          />
        )}
      </DurationGroup>
    )
  }
}
