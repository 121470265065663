import React from 'react'
import { observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Form, Loader, Dimmer } from 'semantic-ui-react'
import { IconButton } from 'spider/semantic-ui/Button'
import styled from 'styled-components'
import { TargetNumberInput } from 'spider/semantic-ui/Target'
import PrintersList from 'component/PrinterHelpers/PrintersList'
import { printPdf, print, getPrinters, getPrinterType } from 'helpers/print'
import { showNotification } from 'helpers/notification'
import PerformStep from './Step'
import { Container, LAST_USED_KEY } from './helpers'


const PrintButtonContainer = styled.div`
  padding: 4rem 0;
  text-align: center;
`

@observer
export default class PerformPrintStep extends PerformStep {
    @observable printData = null
    @observable printed = false
    @observable printing = false
    @observable availablePrinters = null
    @observable selectedPrinter = null
    @observable copies = 1

    constructor(...args) {
        super(...args)

        this.onPrint = this.onPrint.bind(this)
        this.onPrintAll = this.onPrintAll.bind(this)
        this.searchPrinters = this.searchPrinters.bind(this)
    }


    componentDidMount() {
        super.componentDidMount()
        const { step } = this.props

        this.searchPrinters()
        this.copies = step.printStep.copies
    }

    async searchPrinters() {
        const { step } = this.props

        this.availablePrinters = null
        let printerType
        if (step.printStep.type === 'label') {
            printerType = step.printStep.labelPrinter
        } else {
            printerType = 'document'
        }
        let defaultPrinter = step.printStep.defaultPrinter
        const availablePrinters = (await getPrinters()).filter((printer) => getPrinterType(printer) === printerType)

        runInAction(() => {
            this.availablePrinters = availablePrinters

            const lastUsedPrinter = localStorage.getItem(LAST_USED_KEY)
            if (availablePrinters.length === 1) {
                this.selectedPrinter = availablePrinters[0]
            } else if (availablePrinters.includes(defaultPrinter)) {
                this.selectedPrinter = defaultPrinter
            } else if (availablePrinters.includes(lastUsedPrinter)) {
                this.selectedPrinter = lastUsedPrinter
            }
        })
    }

    async onSubmit(quantity) {
        const { onPerform } = this.props

        this.errors = []
        try {
            return await onPerform({ ...this.data, quantity }, this.startedAt)
        } catch (err) {
            if (err.response && err.response.status === 400 && err.response.data.code === 'ValidationError') {
                const errors = err.response.data.errors
                runInAction(() => this.errors = errors)
            }
            throw err
        }
    }

    async onPrint(quantity = 1, skipPrintOnPaper = false) {
        const { step } = this.props
        this.printing = true

        try {
            // Get print data to a uniform format
            let printSettings = step.printStep
            // Actual print
            const performPrint = async (printData, i) => {
                const _copies = this.copies !== printSettings.copies ? this.copies : printSettings.copies
                switch (printSettings.type) {
                    case 'label':
                        await print(this.selectedPrinter, printData.instructions, { copies: _copies, key: i })
                        break

                    case 'document':
                        await printPdf(this.selectedPrinter, printData.document, { copies: _copies, key: i })
                        localStorage.setItem(LAST_USED_KEY, this.selectedPrinter)
                        break

                    default:
                        throw new Error(`unknown print type: ${printSettings.type}`)
                }
            }

            const printPromises = [];
            if (this.printData === null) {
                const allPrintData = [];
                try {
                    for (let i = 0; i < quantity; i++) {
                        const printData = await this.onSubmit();
                        allPrintData.push(printData);
                        if (!skipPrintOnPaper) {
                            printPromises.push(performPrint(printData, i));
                        }
                    }
                } catch (error) {
                    if (allPrintData.length === 0) {
                        throw error;
                    }
                }
                this.printData = allPrintData;
            } else if (!skipPrintOnPaper) {
                printPromises.push(...this.printData.map(performPrint));
            }

            await Promise.all(printPromises);

            this.printed = true
            showNotification(skipPrintOnPaper
                ? t('workStation.production.performModal.printStep.printSkipped')
                : t('workStation.production.performModal.printStep.printedSuccesfully')
            )
        } catch (err) {
            window.viewStore.showNotification({
                key: step.id,
                error: true,
                dismissAfter: 4000,
                message: `${err}`,
                icon: 'print',
            })
        } finally {
            this.printing = false
        }
    }

    async onPrintAll() {
        const { maxPerformCount } = this.props
        return this.onPrint(maxPerformCount)
    }

    renderContent() {
        let printers
        if (this.availablePrinters === null) {
            printers = (
                <Dimmer active inverted>
                    <Loader size="big" />
                </Dimmer>
            )
        } else if (this.availablePrinters.length === 0) {
            printers = (
                <>
                    {t('workStation.production.performModal.printStep.noPrintersAvailable')}
                    <IconButton name="refresh" onClick={this.searchPrinters} />
                </>
            )
        } else {
            printers = (
                <>
                    <PrintersList
                        printers={this.availablePrinters}
                        onSelect={(printer) => this.selectedPrinter = printer}
                    />
                    <div style={{ display: 'grid', placeItems: 'center' }}>
                        <label><b>{t('printModal.copies')}</b></label>
                        <TargetNumberInput data-test-number-of-copies
                            value={this.copies === null ? '' : this.copies.toString()}
                            onChange={(value) => this.copies = value === '' ? null : parseInt(value)}
                        />
                    </div>
                </>
            )
        }

        return (
            <Container>
                <PrintButtonContainer>
                    <Form>
                        <Form.Field>
                            <label>{t('workStation.production.performModal.printStep.selectPrinter')}</label>
                            {printers}
                        </Form.Field>
                        <Button primary data-test-print-button
                            icon="print"
                            labelPosition="left"
                            size="massive"
                            content={t('workStation.production.performModal.printStep.printButton')}
                            onClick={() => this.onPrint()}
                            loading={this.printing}
                            disabled={this.selectedPrinter === null || this.copies === null}
                        />
                        <Button primary data-test-skip-print-button
                            icon="print"
                            labelPosition="left"
                            size="massive"
                            content={t('workStation.production.performModal.printStep.skipPrintButton')}
                            onClick={() => this.onPrint(1, true)}
                            loading={this.printing}
                            disabled={this.selectedPrinter === null || this.copies === null}
                        />
                    </Form>
                </PrintButtonContainer>
            </Container>
        )
    }

}
