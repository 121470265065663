import React, { Component }  from 'react';
import { observer } from 'mobx-react';
import { DownloadButton } from 'spider/semantic-ui/Button'
import PropTypes from 'prop-types';
import { Integration } from 'store/Integration';
import { Batch, BatchStore } from 'store/Batch';
import { observable } from 'mobx';

@observer
export default class RadanExportStep extends Component {
  static propTypes = {
    integration: PropTypes.instanceOf(Integration).isRequired,
    batch: PropTypes.instanceOf(Batch).isRequired
  }

  @observable integration;

  componentDidMount() {
    const { integration } = this.props;
    this.integration = new Integration({ id: integration.id }, {
      relations: ['radanIntegration']
    });
    this.integration.fetch()

  }

  @observable isDownloading = false

  downloadXml = async () => {
    this.isDownloading = true;

    const batchStore = new BatchStore()
    batchStore.add(batchStore.toJS());

    // Note that Radan Integration is currently stateless, meaning that we do not have to fetch extra data right now
    const radanIntegration = this.integration.radanIntegration;
    const response = await radanIntegration.createExportXml(batchStore)



    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'tracy-radan-export.rpd');
    document.body.appendChild(link);
    link.click();

    this.isDownloading = false;
  }


  render() {
    const disableDownload = this.isDownloading
    return (
      <>
        <DownloadButton compact primary data-test-export-radan-button
            icon="upload" labelPosition="right"
            content={t('radan.export')}
            onClick={this.downloadXml}
            disabled={disableDownload}
            data-test-download-disabled={disableDownload}
        />
      </>
    )
  }

}
