import {
    ByProductStep,
    CarrierStep,
    ExportStep,
    FormStep,
    ImportStep,
    MultiplierStep, NestStep,
    PrintStep,
    SplitStep,
    SubprocessesStep
} from 'store/Step';

import { registerStep } from './index';
import PerformMultiExportStep from '../../container/Perform/MultiStep/Export';


/**
 * All different step types that can be defined
 */
export type StepType =
    'print' | 'form' | 'multiplier' | 'split' | 'subprocesses' | 'export' | 'import' | 'carrier' | 'byproduct' | 'nest'


/**
 * Configure the process
 */
export function config(): void {


    registerStep('print', {
        model: () => PrintStep
    })

    registerStep('form', {
        model: () => FormStep
    })

    registerStep('multiplier', {
        model: () => MultiplierStep
    })

    registerStep('split', {
        model: () => SplitStep
    })

    registerStep('subprocesses', {
        model: () => SubprocessesStep
    })

    registerStep('export', {
        // @ts-ignore
        model: () => ExportStep,
        multiStepView: () => PerformMultiExportStep
    })

    registerStep('import', {
        // @ts-ignore
        model: () => ImportStep
    })

    registerStep('carrier', {
        model: () => CarrierStep
    })

    registerStep('byproduct', {
        model: () => ByProductStep
    })

    registerStep('nest', {
        model: () => NestStep
    })
}
