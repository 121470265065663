import React, { Component } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { TargetBase } from 'spider/semantic-ui/Target'
import { Dropdown } from 'semantic-ui-react';

@observer
class MultiTextInput extends Component {
    static propTypes = {
        value: PropTypes.arrayOf(PropTypes.string).isRequired,
        onChange: PropTypes.func.isRequired,
        errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    constructor(...args) {
        super(...args);
        this.onChange = this.onChange.bind(this);
    }

    @computed get options() {
        const { value } = this.props;
        return value.map((item) => ({ value: item, text: item }));
    }

    onChange(e, { value }) {
        const { onChange } = this.props;
        onChange(value);
    }

    onAddItem() {
        // no op
    }

    renderLabel = (label) => ({
        color: this.props.errors.includes(label.text) ? 'red' : '#E8E8E8',
        content: label.text,
    })

    render() {
        const { value, onChange, ...props } = this.props;
        return (
            <Dropdown
                selection fluid multiple search allowAdditions
                icon={null} noResultsMessage={null}
                options={this.options}
                value={value}
                onAddItem={this.onAddItem}
                onChange={this.onChange}
                renderLabel={this.renderLabel}
                {...props}
            />
        );
    }
}

export class CustomTargetMultiTextInput extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.arrayOf(PropTypes.string.isRequired),
    };

    fromStore(value) {
        if (value === undefined) {
            return [];
        }

        return value.split(',');
    }

    toStore(value) {
        if (value.length === 0) {
            return undefined;
        }

        return value.join(',');
    }

    renderErrors(props) { }

    renderContent(props) {
        return (
            <MultiTextInput
                value={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}
