import React from 'react'
import PropTypes from 'prop-types'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react'
import AdminOverview from 'component/AdminOverview'
import { Modal, Button } from 'semantic-ui-react'
import { ArticleTypeStore } from 'store/ArticleType'
import { ProductionLineStore } from 'store/ProductionLine'
import ContentScrollModal from 'spider/semantic-ui/ContentScrollModal'
import styled from 'styled-components'
import { LabelDot } from 'spider/semantic-ui/'

const StyledModalContent = styled(Modal.Content)`
  padding: 0 !important;
  overflow-y: hidden !important;
`

@observer
export default class SelectModal extends AdminOverview {
  static propTypes = {
    trigger: PropTypes.node.isRequired,
    onSelect: PropTypes.func.isRequired,
    relations: PropTypes.arrayOf(PropTypes.string.isRequired),
  }

  static defaultProps = {
    relations: [],
  }

  constructor(...args) {
    super(...args)
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  store = new ArticleTypeStore({
    relations: this.props.relations,
  })
  productionLineStore = new ProductionLineStore()

  @observable open = false

  @action onOpen() {
    this.open = true
    this.store.fetch()
  }

  @action onClose() {
    this.open = false
    this.store.clear()
  }
  modal = true
  fetchOnMount = false
  bindUrlParams = false

  settings = [
    'code',
    'name',
    {
      label: t('articleType.field.versions.label'),
      attr: (articleType) => (
        <Button.Group>
          {articleType.versions.map((version, i) => (
            <Button
              compact
              key={version.id}
              content={version.version}
              primary={
                !version.draft && (articleType.versions.length === i + 1 || articleType.versions.at(i + 1).draft)
              }
              style={version.draft ? { opacity: 0.5 } : undefined}
              onClick={() => {
                this.onClose()
                this.props.onSelect(version, articleType)
              }}
            />
          ))}
        </Button.Group>
      ),
      cellProps: { style: { textAlign: 'right' } },
      collapsing: true,
    },
  ]

  filters = [
    {
      type: 'text',
      label: t('articleType.field.code.label'),
      name: '.code:icontains',
    },
    {
      type: 'text',
      label: t('articleType.field.name.label'),
      name: '.name:icontains',
    },
    () => ({
      type: 'multiPick',
      label: t('articleType.field.line.label'),
      name: '.line:in',
      store: this.productionLineStore,
      toOption: (productionLine) => ({
        value: productionLine.id,
        text: (
          <React.Fragment>
            <LabelDot color={productionLine.color} marginLeft />
            {productionLine.name}
          </React.Fragment>
        ),
      }),
      remote: true,
      searchKey: '.name:icontains',
      targetProps: { type: 'int' },
    }),
  ]

  renderBody() {
    const { trigger } = this.props
    return (
      <ContentScrollModal
        closeIcon
        size="fullscreen"
        open={this.open}
        onOpen={this.onOpen}
        onClose={this.onClose}
        trigger={trigger}
      >
        <Modal.Header>{t('articleType.selectModal.title')}</Modal.Header>
        <StyledModalContent>{super.renderBody()}</StyledModalContent>
      </ContentScrollModal>
    )
  }
}
