import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Popup, Label } from 'semantic-ui-react'
import styled from 'styled-components'
import { LabelDot } from 'spider/semantic-ui';

// stores
import { MARKED_COLORS, ProductionRequest } from 'store/ProductionRequest'
import { SalesOrder } from 'store/SalesOrder'
import { PurchaseOrder } from 'store/PurchaseOrder'
// end stores

const StyledColorMarkingLabelContainer = styled.div`
    height: 6px;
    transform: translate(0.1em, -0.85em)
`;

const ColorMarkingLabel = styled(Label)`
    padding: 0px !important;
    height: 6px !important;
    width: 16px !important;
`;

export default class ColorMarkingLabelContainer extends Component {
    static propTypes = {
      target: PropTypes.oneOfType([
        PropTypes.instanceOf(SalesOrder).isRequired,
        PropTypes.instanceOf(PurchaseOrder).isRequired,
        PropTypes.instanceOf(ProductionRequest).isRequired,
      ]).isRequired,
      colorMarkingNames: PropTypes.object.isRequired,
    }

    render() {
      const { target, colorMarkingNames } = this.props

      return <StyledColorMarkingLabelContainer>
        {MARKED_COLORS.filter(color => target.markedColors.includes(color)).map(color =>
          <Popup
            content={color in colorMarkingNames ? colorMarkingNames[color] : t('color.' + color)}
            trigger={<ColorMarkingLabel data-test-color-marking={color} key={color} size="mini" color={color} empty />}
            position="top center"
          />
        )}
      </StyledColorMarkingLabelContainer>
    }
}

export const markedColorFilter = (name='.marked_colors:contains') => {
    return {
        type: 'multiPick',
        label: t('productionRequest.filter.markedColors'),
        name: name,
        options: [
        ...MARKED_COLORS.map(color => ({
            value: color,
            text: <div>
                <LabelDot color={color} style={{ marginLeft: '4px' }}/>
                <span>{t('color.' + color)}</span>
            </div>
        })),{
            value: 'none', //option to pick "colorless", filter fixed in view
            text:<div>
                <LabelDot color={'white'} style={{ marginLeft: '4px' }}/>
                <span>{t('color.none')}</span>
            </div>,
        }
        ],
    }
}
