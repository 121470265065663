export const DAYS = 'days'
export const MINUTES = 'minutes'
export const HOURS = 'hours'
export const SECONDS = 'seconds'

/**
 * function to convert input time into minutes
 * paramenter baseTime: time given as input
 * paramenter baseUnit: time unit given as input
 * return value: converted time in minutes as output */
export function convertToMinutes(baseTime, baseUnit) {

  switch (baseUnit) {
    case SECONDS:
      return baseTime / 60
    case MINUTES:
      return baseTime
    case HOURS:
      return baseTime * 60
    case DAYS:
      return baseTime * 1440
    default:
      return null
  }

}
