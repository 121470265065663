import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Warehouse } from './Warehouse'
import { ArticleTypeWarehouseStore } from './ArticleTypeWarehouse'

export class StorageLocation extends Model {
  static backendResourceName = 'storage_location'

  @observable id = null
  @observable code = ''
  @observable name = ''
  @observable deleted = false

  relations() {
    return {
      warehouse: Warehouse,
      articleTypeWarehouses: ArticleTypeWarehouseStore,
    }
  }
}

export class StorageLocationStore extends Store {
  Model = StorageLocation
  static backendResourceName = 'storage_location'
}
