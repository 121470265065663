import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable, action, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { ProductionRequest } from 'store/ProductionRequest'
import { ItemButton } from 'component/AdminOverview'
import { ArticleTypeStore } from 'store/ArticleType'
import { Casts } from 'store/Base'
import { TargetSelect, TargetNumberInput, serializeWheres } from 'spider/semantic-ui/Target'
import moment from 'moment'
import { showSaveNotification } from 'helpers/notification'
import { NavLink } from 'react-router-dom'
import { Modal, Form } from 'semantic-ui-react'
import { CancelButton, SaveButton } from 'spider/semantic-ui/Button'
import RightDivider from 'spider/component/RightDivider'
import { WarehouseStore } from 'store/Warehouse'
import { StorageLocationStore } from 'store/StorageLocation'
import styled from 'styled-components'

const SmallTargetSelect = styled(TargetSelect)`
  > .ui.dropdown {
    min-width: 0 !important;
  }
`


/**
 * Modal to add a production request for stock count processes.
 */
@observer
export default class AddStockCountModal extends Component {
  static propTypes = {
    afterSave: PropTypes.func.isRequired,
  }

  constructor(...args) {
    super(...args)
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.save = this.save.bind(this)

    this.productionRequest.setFetchParams({
      where: serializeWheres({
        'process_version.batch_type.article_type.batch_types': {
          'type': 'stock_count',
          'deleted': 'false',
          'process_versions.draft': 'false',
        },
        'process_version.batch_type.article_type.batch_types.process_versions': {
          'draft': 'false',
        },
      }),
    });
  }

  articleTypeStore = new ArticleTypeStore({
    relations: [
      'exactItem',
      'batchTypes.processVersions',
    ],
    params: {
      where: serializeWheres({
        'batch_types': {
          'type': 'stock_count',
          'deleted': 'false',
          'process_versions.draft': 'false',
        },
        'batch_types.process_versions': {
          'draft': 'false',
        },
      }),
    },
  })

  warehouseStore = new WarehouseStore()

  storageLocationStore = new StorageLocationStore()

  @observable productionRequest = new ProductionRequest({}, {
    relations: [
      'processVersion.batchType.articleType.exactItem',
      'processVersion.batchType.articleType.batchTypes.processVersions',
      'processVersion.steps',
      'stockCount.warehouse',
      'stockCount.defaultStorageLocation',
      'articleType'
    ],
  })

  @observable open = false

  componentDidMount() {
    // Filter warehouses on selected article type
    this.articleTypeReaction = reaction(
      () => this.productionRequest.processVersion.batchType.articleType.id,
      action((id) => {
        this.warehouseStore.params['.article_type_warehouses.article_type'] = id;
        this.warehouseStore.fetch();
      })
    )

    // Filter storage locations on selected warehouse
    this.warehouseReaction = reaction(
      () => this.productionRequest.stockCount.warehouse.id,
      action((id) => {
        this.storageLocationStore.params['.warehouse'] = id;
        this.productionRequest.stockCount.setInput('defaultStorageLocation', null);
        this.storageLocationStore.fetch();
      })
    )
  }

  componentWillUnmount() {
    this.articleTypeReaction();
    this.warehouseReaction();
  }

  @action onOpen() {
    this.open = true
    this.productionRequest.parse({
      quantity: 1,
      startAt: Casts.datetime.toJS('startAt', moment()),
      year: moment().format('GGGG'),
      week: moment().format('W'),
    })
  }

  @action onClose() {
    this.open = false
    this.productionRequest.clear()
    this.articleTypeStore.clear()
    this.warehouseStore.clear()
  }

  async save() {
    const { afterSave } = this.props
    await this.productionRequest.save({
      relations: ['stockCount'],
    })
    runInAction(() => {
      showSaveNotification()
      afterSave(this.productionRequest)
      this.onClose()
    })
  }

  render() {

    const ObjectCode = styled.span`
      color: #777;
      font-family: monospace, monospace;
      margin-right: 1em;
    `

    return (
      <Modal
        size="tiny"
        open={this.open}
        onOpen={this.onOpen}
        onClose={this.onClose}
        trigger={
          <ItemButton
            primary
            data-test-register-existing-button
            icon="tally"
            labelPosition="left"
            content={t('productionRequest.registerExistingButton')}
          />
        }
      >
        <Modal.Content>
          <Form>
            <TargetSelect remote
              target={this.productionRequest.processVersion.batchType}
              name="articleType"
              store={this.articleTypeStore}
              onChange={action((articleType) => {
                this.productionRequest.articleType = articleType
                const batchType = articleType.batchTypes.models[0]
                const processVersion = batchType?.processVersions?.find(({ newest }) => newest)
                this.productionRequest.setInputFromJS('processVersion', {
                  ...processVersion?.toJS() ?? {},
                  batchType: {
                    ...batchType?.toJS() ?? {},
                    articleType: articleType.toJS(),
                  },
                })
                this.productionRequest.stockCount.setInput('warehouse', null)
              })}
              toOption={(articleType) => ({
                text: <span><ObjectCode>{articleType.code}</ObjectCode>{articleType.name}</span>,
                value: articleType.id,
              })}
            />
            <Form.Group>
              <TargetSelect search
                target={this.productionRequest.processVersion}
                name="batchType"
                type="int"
                fromTarget={(batchType) => batchType?.id ?? null}
                disabled={this.productionRequest.processVersion.batchType.articleType.isNew}
                onChange={action((batchTypeId) => {
                  const articleType = this.productionRequest.processVersion.batchType.articleType
                  const batchType = articleType.batchTypes.get(batchTypeId)
                  const processVersion = batchType?.processVersions?.find(({ newest }) => newest)
                  this.productionRequest.setInputFromJS('processVersion', {
                    ...processVersion?.toJS() ?? {},
                    batchType: {
                      ...batchType.toJS(),
                      articleType: articleType.toJS(),
                    },
                  })
                })}
                options={this.productionRequest.processVersion.batchType.articleType.batchTypes.map((batchType) => ({
                  value: batchType.id,
                  text: batchType.description,
                }))}
                errors={(
                  !this.productionRequest.processVersion.batchType.articleType.isNew &&
                  this.productionRequest.processVersion.batchType.articleType.batchTypes.length === 0
                ) ? [
                  window.viewStore.isWorkStation
                    ? t('stockCount.missingStockCountProcess')
                    : (
                      <NavLink data-test-missing-stock-count-link
                        to={`/assets/article-type/${this.productionRequest.processVersion.batchType.articleType.id}/edit?newType=stock_count`}
                      >
                        {t('stockCount.missingStockCountProcess')}
                      </NavLink>
                    )
                ] : []}
                errorProps={{ color: 'white' }}
                width={12}
              />
              <SmallTargetSelect
                target={this.productionRequest}
                name="processVersion"
                type="int"
                fromTarget={(processVersion) => processVersion?.id ?? null}
                disabled={this.productionRequest.processVersion.batchType.isNew}
                onChange={action((processVersionId) => {
                  const articleType = this.productionRequest.processVersion.batchType.articleType
                  const batchType = articleType.batchTypes.get(this.productionRequest.processVersion.batchType.id)
                  const processVersion = batchType.processVersions.get(processVersionId)
                  this.productionRequest.setInputFromJS('processVersion', {
                    ...processVersion.toJS(),
                    batchType: {
                      ...batchType.toJS(),
                      articleType: articleType.toJS(),
                    },
                  })
                })}
                options={this.productionRequest.processVersion.batchType.isNew ? [] : this.productionRequest.processVersion.batchType.articleType.batchTypes.get(this.productionRequest.processVersion.batchType.id).processVersions.map((processVersion) => ({
                  value: processVersion.id,
                  text: `V${processVersion.version}`,
                }))}
                width={4}
              />
            </Form.Group>
            <TargetNumberInput
              target={this.productionRequest}
              name="quantity"
            />
            <TargetSelect remote
              target={this.productionRequest.stockCount}
              name="warehouse"
              store={this.warehouseStore}
              disabled={this.productionRequest.processVersion.batchType.articleType.isNew}
              toOption={(warehouse) => ({
                value: warehouse.id,
                text: <span><ObjectCode>{warehouse.code}</ObjectCode> {warehouse.name}</span>,
              })}
            />
            <TargetSelect remote
              target={this.productionRequest.stockCount}
              name='defaultStorageLocation'
              store={this.storageLocationStore}
              disabled={this.productionRequest.stockCount.warehouse.isNew || !this.productionRequest.stockCount.warehouse.useStorageLocations}
              toOption={(storageLocation) => ({
                value: storageLocation.id,
                text: <span><ObjectCode>{storageLocation.code}</ObjectCode>{storageLocation.name}</span>,
              })}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <CancelButton data-test-cancel-button onClick={this.onClose} />
          <RightDivider />
          <SaveButton primary data-test-save-inbound-request-button onClick={this.save} />
        </Modal.Actions>
      </Modal>
    )
  }
}
