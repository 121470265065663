import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable, reaction, action, computed } from 'mobx'
import { observer } from 'mobx-react'
import moment from 'moment'
import styled from 'styled-components'

// components
// end components

// helpers
import { SERVER_DATETIME_FORMAT, formatDuration } from 'helpers'
// end helpers

// stores
import { User } from 'store/User'
import { Operator } from 'store/Operator'
import { WorkSlot } from 'store/WorkSlot'
import { WorkTimeStore } from 'store/WorkTime'
// end stores


const TotalOvertime = styled.span`
  font-weight: bold;
  margin-right: 0.75em;
  color: #21ba45;
`

const TotalAbsence = styled.span`
  font-weight: bold;
  margin-right: 0.75em;
  color: #db2828;
`

const TimeBalance = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

function changeOffset(m, offset) {
    return m
        .clone()
        .utcOffset(offset)
        .add(m.utcOffset() - offset, 'm')
}

function getLeaveSlotFilter(scope) {
    return (leaveSlot) =>
        leaveSlot.startDate.clone().startOf('day').isBefore(scope.end) &&
        leaveSlot.endDate.clone().endOf('day').isAfter(scope.start)
}

export function getScopedLeaveSlots(scope, user, operator, leaveCalendar) {
    const target = user || operator || leaveCalendar
    let leaveSlots = target && target.leaveSlots

    // Check if operator is a user and data is stored at user level.
    if (operator && operator.user && !operator.user.isNew && operator.user.leaveSlots && operator.user.leaveSlots.length > 0) {
        leaveSlots = operator.user.leaveSlots
    }
    if (!leaveSlots) {
        return []
    }
    return leaveSlots.filter(getLeaveSlotFilter(scope))
}

export function getScopedLeaveCalendars(scope, user, operator) {
    const target = user || operator
    let leaveCalendars = target && target.leaveCalendars

    // Check if operator is a user and data is stored at user level.
    if (operator && operator.user && !operator.user.isNew && operator.user.leaveCalendars && operator.user.leaveCalendars.length > 0) {
        leaveCalendars = operator.user.leaveCalendars
    }
    if (!leaveCalendars) {
        return []
    }
    const scopedLeaveCalendars = []
    // eslint-disable-next-line
    for (const leaveCalendar of leaveCalendars.models) {
        const leaveSlots = leaveCalendar.leaveSlots.filter(getLeaveSlotFilter(scope))
        if (leaveSlots.length > 0) {
            scopedLeaveCalendars.push({ leaveCalendar, leaveSlots })
        }
    }
    return scopedLeaveCalendars
}

function getFlatLeaveSlots(scope, user, operator, leaveCalendar) {
    const flatLeaveSlots = []
    flatLeaveSlots.push(...getScopedLeaveSlots(scope, user, operator, leaveCalendar))
    // eslint-disable-next-line
    for (const { leaveSlots } of getScopedLeaveCalendars(scope, user, operator)) {
        flatLeaveSlots.push(...leaveSlots)
    }
    return flatLeaveSlots
}

function getScopedWorkSlots(scope, user, operator) {
    const target = user || operator
    let workSchedules = target && target.workSchedules

    // Check if operator is a user and data is stored at user level.
    if (operator && operator.user && !operator.user.isNew && operator.user.workSchedules && operator.user.workSchedules.length > 0) {
        workSchedules = operator.user.workSchedules
    }
    if (!workSchedules || workSchedules.length === 0) {
        return []
    }

    workSchedules = workSchedules.models
        .slice()
        .sort((l, r) => {
            if (l.startDate.isBefore(r.startDate)) {
                return -1
            } else if (l.startDate.isAfter(r.startDate)) {
                return 1
            } else {
                return 0
            }
        })

    let workSchedule = 0

    const scopedWorkSlots = []
    for (let day = scope.start.clone(); day.isBefore(scope.end); day.add(1, 'd')) {
        while (workSchedule < workSchedules.length - 1 && day.isSameOrAfter(workSchedules[workSchedule + 1].startDate)) {
            workSchedule++
        }
        // eslint-disable-next-line
        for (const workSlot of workSchedules[workSchedule].workSlots.models) {
            if (workSlot[WorkSlot.DAYS[day.isoWeekday() - 1]]) {
                const start = day
                    .clone()
                    .hour(workSlot.startTime.hour())
                    .minute(workSlot.startTime.minute())
                    .second(workSlot.startTime.second())
                const end = day
                    .clone()
                    .hour(workSlot.endTime.hour())
                    .minute(workSlot.endTime.minute())
                    .second(workSlot.endTime.second())
                scopedWorkSlots.push({ start, end, isBreak: workSlot.isBreak })
            }
        }
    }
    return scopedWorkSlots
}

export function getScopedWorkedSlots(scope, user, operator, leaveCalendar, workTimes, now) {
    // So to combine work slots and work times we first turn it into one
    // big array of events
    const events = []
    //eslint-disable-next-line
    for (const { start, end, isBreak } of getScopedWorkSlots(scope, user, operator)) {
        events.push({
            type: isBreak ? 'breakStart' : 'workSlotStart',
            time: start,
        })
        events.push({
            type: isBreak ? 'breakEnd' : 'workSlotEnd',
            time: end,
        })
        // If work times are not loaded we assume perfect attendance
        if (workTimes === null || workTimes.isLoading) {
            events.push({
                type: 'workTimeStart',
                time: start,
            })
            events.push({
                type: 'workTimeEnd',
                time: end,
                automaticallyClockedOut: false,
            })
        }
    }
    // eslint-disable-next-line
    for (const { startDate, endDate } of getFlatLeaveSlots(scope, user, operator, leaveCalendar)) {
        events.push({
            type: 'leaveSlotStart',
            time: startDate,
        })
        events.push({
            type: 'leaveSlotEnd',
            time: endDate,
        })
    }

    if (workTimes !== null) {
        if (!workTimes.isLoading) {
            // eslint-disable-next-line
            for (let { startTime, endTime, automaticallyClockedOut } of workTimes.models) {
                // Normalize offset
                startTime = changeOffset(startTime, scope.start.utcOffset())
                endTime = endTime && changeOffset(endTime, scope.start.utcOffset())
                // Fit within scope
                startTime = startTime.isBefore(scope.start) ? scope.start : startTime
                endTime = endTime && (endTime.isAfter(scope.end) ? scope.end : endTime)
                // Add events
                events.push({ type: 'workTimeStart', time: startTime })
                if (endTime) {
                    events.push({ type: 'workTimeEnd', time: endTime, automaticallyClockedOut })
                }
            }
        }
        if (now.isBefore(scope.end)) {
            events.push({
                type: 'currentTime',
                time: now,
            })
        }
    }

    // We can now sort these events so that we can easily process them
    events.sort((l, r) => l.time.diff(r.time))

    // Now we create a list of slots based on these events
    const slots = []
    let slot = null

    let future = false
    let working = 0
    let workPlanned = 0
    let breakPlanned = 0
    let leave = 0
    let automaticClockOut = null

    // eslint-disable-next-line
    for (const { type, time, automaticallyClockedOut = false } of events) {
        switch (type) {
            case 'currentTime':
                future = true
                break
            case 'workSlotStart':
                workPlanned++
                break
            case 'workSlotEnd':
                workPlanned--
                break
            case 'breakStart':
                breakPlanned++
                break
            case 'breakEnd':
                breakPlanned--
                break
            case 'leaveSlotStart':
                leave++
                break
            case 'leaveSlotEnd':
                leave--
                break
            case 'workTimeStart':
                working++
                break
            case 'workTimeEnd':
                working--
                break
            default:
                console.warn('Unexpected event type:', type)
        }

        if (automaticallyClockedOut) {
            automaticClockOut = time
        } else if (automaticClockOut && !automaticClockOut.isSame(time)) {
            automaticClockOut = null
        }

        let state;
        if (leave > 0) {
            state = null;
        } else if (breakPlanned > 0) {
            state = 'break'
        } else if (workPlanned > 0) {
            if (working > 0 || future) {
                state = 'planned'
            } else {
                state = 'absent'
            }
        } else if (working > 0 && !future) {
            state = 'overtime'
        }

        if (slot && slot.state !== state) {
            if (!time.isSame(slot.start)) {
                slots.push({ ...slot, end: time, automaticallyClockedOut: automaticClockOut !== null })
            } else if (automaticallyClockedOut && slots.length > 0 && slots[slots.length - 1].end.isSame(time)) {
                slots[slots.length - 1].automaticallyClockedOut = true
            }
            slot = null
        }
        if (!slot && state) {
            slot = { state, start: time }
        }
    }

    if (slot && !scope.end.isSame(slot.start)) {
        slots.push({ ...slot, end: scope.end })
    }

    // Now we seperate these slots based on days
    const daySlots = []
    // eslint-disable-next-line
    for (let { state, start, end, automaticallyClockedOut } of slots) {
        let wrapTop = false
        while (true) {
            const endOfDay = start.clone().endOf('day')
            if (endOfDay.isBefore(end)) {
                daySlots.push({
                    state,
                    start,
                    end: endOfDay,
                    wrapTop,
                    wrapBottom: true,
                    automaticallyClockedOut: false,
                })

                wrapTop = true
                start = start.clone().startOf('day').add(1, 'day')
            } else {
                daySlots.push({
                    state,
                    start,
                    end,
                    wrapTop,
                    wrapBottom: false,
                    automaticallyClockedOut,
                })
                break
            }
        }
    }

    // Now we group absent slots into planned slots
    const groupedSlots = []
    // eslint-disable-next-line
    for (const slot of daySlots) {
        const groupState = slot.state === 'absent' ? 'planned' : slot.state

        if (
            groupedSlots.length > 0 &&
            groupedSlots[groupedSlots.length - 1].end.isSame(slot.start) &&
            groupedSlots[groupedSlots.length - 1].state === groupState
        ) {
            groupedSlots[groupedSlots.length - 1].end = slot.end
            groupedSlots[groupedSlots.length - 1].wrapBottom = slot.wrapBottom
        } else {
            const group = {
                ...slot,
                state: groupState,
                overlays: [],
                flatTop: false,
                flatBottom: false,
            }
            if (groupedSlots.length > 0 && groupedSlots[groupedSlots.length - 1].end.isSame(group.start)) {
                groupedSlots[groupedSlots.length - 1].flatBottom = true
                group.flatTop = true
            }
            groupedSlots.push(group)
        }

        const group = groupedSlots[groupedSlots.length - 1]
        if (slot.state !== groupState) {
            group.overlays.push({ state: slot.state, start: slot.start, end: slot.end })
        }
    }

    // Now we normalize start and end of overlays into numbers from 0 to 1
    // eslint-disable-next-line
    for (const { start, end, overlays } of groupedSlots) {
        if (overlays.length > 0) {
            const duration = end.diff(start)
            // eslint-disable-next-line
            for (const overlay of overlays) {
                overlay.startPos = overlay.start.diff(start) / duration
                overlay.endPos = overlay.end.diff(start) / duration
            }
        }
    }

    return groupedSlots
}

function getScopedWorkedSlotsUntilTime(scope, user, operator, leaveCalendar, workTimes, now) {
    const slots = []
    // eslint-disable-next-line
    for (const slot of getScopedWorkedSlots(scope, user, operator, leaveCalendar, workTimes, now)) {
        if (slot.start.isSameOrAfter(now)) {
            // Slot is after current time
            break // Slots are in order so we can stop
        }
        if (slot.end.isSameOrBefore(now)) {
            // Slot is fully contained
            slots.push(slot)
            continue
        }
        const newSlot = { ...slot, end: now }
        if (slot.overlays) {
            newSlot.overlays = []
            // eslint-disable-next-line
            for (const overlay of slot.overlays) {
                if (overlay.start.isSameOrAfter(now)) {
                    // Overlay is after current time
                    break // Overlays are in order so we can stop
                }
                if (overlay.end.isSameOrBefore(now)) {
                    // Overlay is fully contained
                    newSlot.overlays.push(overlay)
                    continue
                }
                newSlot.overlays.push({ ...overlay, end: now })
            }
        }
        slots.push(newSlot)
    }
    return slots
}

export function getTotalTimePlanned(scope, user, operator, leaveCalendar, workTimes, now) {
    let totalTimePlanned = 0

    // eslint-disable-next-line
    for (const { state, start, end } of getScopedWorkedSlotsUntilTime(scope, user, operator, leaveCalendar, workTimes, now)) {
        if (state === 'planned') {
            totalTimePlanned += end.diff(start, 'm', true)
        }
    }

    return Math.round(totalTimePlanned)
}

export function getTotalTimeActual(scope, user, operator, leaveCalendar, workTimes, now) {
    let totalTimeActual = 0

    // eslint-disable-next-line
    for (const { state, start, end, overlays } of getScopedWorkedSlotsUntilTime(scope, user, operator, leaveCalendar, workTimes, now)) {
        if (state !== 'break') {
            totalTimeActual += end.diff(start, 'm', true)
        }

        if (state === 'planned') {
            // eslint-disable-next-line
            for (const { state, start, end } of overlays) {
                if (state === 'absent') {
                    totalTimeActual -= end.diff(start, 'm', true)
                }
            }
        }
    }

    return Math.round(totalTimeActual)
}

@observer
export default class WorkBalance extends Component {
    static propTypes = {
        user: PropTypes.instanceOf(User),
        operator: PropTypes.instanceOf(Operator),
        scopeStart: PropTypes.instanceOf(moment),
        year: PropTypes.number,
        week: PropTypes.number,
        month: PropTypes.number,
    }

    constructor(...args) {
        super(...args)

        this.renderTimeBalance = this.renderTimeBalance.bind(this)
    }

    @observable now = moment()
    @observable workTimes = new WorkTimeStore({ limit: false })

    componentDidMount() {
        this.scopeReaction = reaction(
            () => [this.props.user, this.props.operator, this.props.scopeStart],
            action(([user, operator, start]) => {
                const filterUserOrOperator = {}

                if (user) {
                    filterUserOrOperator['.user'] = user.id
                } else if (operator && operator.user && !operator.user.isNew) {
                    // Check if operator is a user and data is stored at user level.
                    filterUserOrOperator['.user'] = operator.user.id
                } else if (operator) {
                    filterUserOrOperator['.operator'] = operator.id
                }

                const yearScopeStart = moment({ year: start.isoWeekYear(), month: 1, day: 1 }).startOf('day')
                const yearScopeEnd = yearScopeStart.clone().endOf('year')

                this.workTimes.params = {
                    '.end_time:gte_or_isnull': yearScopeStart.format(SERVER_DATETIME_FORMAT),
                    '.start_time:lte': yearScopeEnd.format(SERVER_DATETIME_FORMAT),
                    ...filterUserOrOperator
                }
                this.workTimes.fetch()
            }),
            { fireImmediately: true }
        )
    }

    componentWillUnmount() {
        this.scopeReaction()
    }

    @computed get scopes() {
        let { year, week, month } = this.props
        if (week === undefined) {
            week = this.now.isoWeek()
        }
        if (month === undefined) {
            month = this.now.month()
        }
        return {
            'week': {
                start: moment().isoWeekYear(year).isoWeekday(1).isoWeek(week).startOf('day'),
                end: moment().isoWeekYear(year).isoWeekday(1).isoWeek(week).startOf('day').clone().endOf('isoWeek'),
            },
            'month': {
                start: moment({ year: year, month: month, day: 1 }).startOf('day'),
                end: moment({ year: year, month: month, day: 1 }).startOf('day').clone().endOf('month'),
            },
            'year': {
                start: moment({ year: year, month: 1, day: 1 }).startOf('day'),
                end: moment({ year: year, month: 1, day: 1 }).startOf('day').clone().endOf('year'),
            }
        }
    }

    renderTimeBalance(key) {
        const { user, operator } = this.props

        const planned = getTotalTimePlanned(this.scopes[key], user, operator, null, this.workTimes, this.now)
        const actual = getTotalTimeActual(this.scopes[key], user, operator, null, this.workTimes, this.now)
        const Total = planned > actual ? TotalAbsence : TotalOvertime
        const balance = planned > actual ? planned - actual : actual - planned
        return (
            <TimeBalance>
                <span>{key[0].toUpperCase()}</span>
                <Total>
                    {formatDuration(balance, { unit: 'minute', maxUnit: 'hour' })}
                </Total>
            </TimeBalance>
        )
    }


    render() {
        if (this.workTimes === null) {
            return
        }

        return (
            <div>
                {Object.keys(this.scopes).map(this.renderTimeBalance)}
            </div>
        )
    }
}
