import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { FormField } from './FormField'
import { Performance } from './Performance'
import { BatchStore } from './Batch'
import { StorageLocation } from './StorageLocation'
import { DetailImageStore } from './DetailImage'
import { Metavalue } from './Metavalue'
import { DetailMaterialTaskStore } from './DetailMaterialTask'

export class Detail extends Model {
  static backendResourceName = 'detail'

  @observable id = null
  @observable value = null
  @observable reason = null

  relations() {
    return {
      field: FormField,
      performance: Performance,
      components: BatchStore,
      images: DetailImageStore,
      storageLocation: StorageLocation,
      metavalue: Metavalue,
      materials: DetailMaterialTaskStore,
    }
  }
}

export class DetailStore extends Store {
  Model = Detail
  static backendResourceName = 'detail'
}
