import { trimEnd } from 'lodash'
import Decimal from 'decimal.js';

export const QUANTITY_DECIMAL_PLACES = 6;


/**
 * @deprecated Use Decimal.js
 * @param value
 */
export function parseDecimal(value: number | string): [number, number] {
  if (typeof value === 'number') {
    value = value.toString()
  }
  let order = 0
  // Remove decimal
  const decimal = value.indexOf('.')
  if (decimal !== -1) {
    value = value.slice(0, decimal) + value.slice(decimal + 1)
    order = decimal - value.length
  }
  // Trim trailing zeroes
  let trim = 0
  while (trim < value.length - 1 && value[value.length - 1 - trim] === '0') {
    trim++
  }
  if (trim > 0) {
    value = value.slice(0, -trim)
    order += trim
  }
  return [parseInt(value), order]
}

/**
 * @deprecated Use Decimal.js
 * @param value
 * @param order
 */
export function serializeDecimal([value, order]: [number | string, number]) {
  if (value === 0) {
    return '0'
  }
  value = value.toString()
  if (order > 0) {
    value = value + '0'.repeat(order)
  } else if (order < 0) {
    if (value.length < -order + 1) {
      value = '0'.repeat(-order + 1 - value.length) + value
    }
    value = value.slice(0, order) + '.' + value.slice(order)
    let length = value.length
    while (value[length - 1] === '0') {
      length--
    }
    if (value[length - 1] === '.') {
      length--
    }
    if (length !== value.length) {
      value = value.slice(0, length)
    }
  }
  return value
}

/**
 * @deprecated Use Decimal.js
 * @param decimal
 * @param multipliers
 */
export function multiplyDecimals(decimal, ...multipliers) {
  let [value, order] = parseDecimal(decimal)
  // eslint-disable-next-line
  for (const multiplier of multipliers) {
    const [multiplierValue, multiplierOrder] = parseDecimal(multiplier)
    value *= multiplierValue
    order += multiplierOrder
  }
  return serializeDecimal([value, order])
}

/**
 * @deprecated Use Decimal.js
 * @param decimal
 * @param additions
 */
export function addDecimals(decimal, ...additions) {
  let [value, order] = parseDecimal(decimal)
  // eslint-disable-next-line
  for (const addition of additions) {
    let [additionValue, additionOrder] = parseDecimal(addition)
    if (additionValue === 0) {
      continue
    } else if (value === 0) {
      value = additionValue
      order = additionOrder
      continue
    }
    while (order > additionOrder) {
      value *= 10
      order--
    }
    while (additionOrder > order) {
      additionValue *= 10
      additionOrder--
    }
    value += additionValue
  }
  const res = serializeDecimal([value, order])
  return res
}

/**
 * @deprecated Use Decimal.js
 * @param decimal
 */
export function invertDecimal(decimal) {
  const [value, order] = parseDecimal(decimal)
  return serializeDecimal([-value, order])
}

/**
 * @deprecated Use Decimal.js
 * @param decimal
 * @param subtractions
 */
export function subtractDecimals(decimal, ...subtractions) {
  return addDecimals(decimal, ...subtractions.map(invertDecimal))
}
/**
 * @deprecated Use Decimal.js
 * @param l
 * @param r
 */
export function compareDecimals(l, r) {
  return parseDecimal(subtractDecimals(l, r))[0]
}

/**
 * @deprecated Use Decimal.js
 * @param decimal
 * @param otherDecimals
 */
export function minDecimals(decimal, ...otherDecimals) {
  // eslint-disable-next-line
  for (const otherDecimal of otherDecimals) {
    if (compareDecimals(decimal, otherDecimal) > 0) {
      decimal = otherDecimal
    }
  }
  return decimal
}

/**
 *
 * @param {string} value
 * @param {int} decimalNumbers
 * @returns {string} '5.123' => '5.123', '5.000' => '5', '5.1' => '5.1'
 */
export function humanReadable(valueStringOrDecimal: string | Decimal, decimalNumbers: number = QUANTITY_DECIMAL_PLACES) {
  let value = ''

  if (valueStringOrDecimal instanceof Decimal) {
      value = valueStringOrDecimal.toString()
  } else {
      value = valueStringOrDecimal
  }

  try {
    const fixed = parseFloat(value).toFixed(decimalNumbers)
    const onlyZeroesSuffix = '.' + '0'.repeat(decimalNumbers)

    if (fixed.endsWith(onlyZeroesSuffix)) {
      return fixed.slice(0, -onlyZeroesSuffix.length)
    }

    // If we are not displaying decimals, don't trim the zeros since those are relevant
    return decimalNumbers > 0 ? trimEnd(fixed, '0') : fixed
  } catch (e) {
      // If we cannot parse the input, fallback to just giving back the input
  }

  return value
}

/**
 * Although in general it is better to use Decimals where possible, there are some places where precision doesn't matter,
 * and existing interfaces already expect floating points. E.g. calculation of progress bar
 *
 * This method makes sure that the input is always returned as an output
 *
 * @param number
 */
export function toNumber(number: number | Decimal): number {
    if (number instanceof Decimal) {
        return number.toNumber();
    }
    return number;
}
