import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { TargetTextInput } from 'spider/semantic-ui/Target'
import RightDivider from 'spider/component/RightDivider'
import { api } from 'store/Base'
import TargetInfoModal from 'component/TargetInfoModal'
import { Operator } from 'store/Operator'
import { theme } from 'styles'
import { BatchStore } from 'store/Batch'
import styled from 'styled-components'


const Options = styled.div`
  margin-top: 1rem;
  > code {
    color: ${theme.primaryColor};
  }
`

@observer
export default class ScanToPerformModal extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        operator: PropTypes.instanceOf(Operator),
        batches: PropTypes.instanceOf(BatchStore).isRequired,
        onStepSelect: PropTypes.func,
        onShow: PropTypes.func,
        triggerProps: PropTypes.object.isRequired,
    }

    @observable value = ''
    @observable error = null
    @observable batch = null
    @observable loadCarrier = null
    @observable showBatchInfo = false
    @observable isOpen = false

    open = () => {
        this.isOpen = true
        if (this.props.onShow) {
            this.props.onShow(true)
        }
    }
    close = () => {
        this.value = ''
        this.error = null
        this.batch = null
        this.isOpen = false
        if (this.props.onShow) {
            this.props.onShow(false)
        }
    }
    onUnmount = () => (this.value = '')

    onChange = (value) => {
        this.value = value
        this.batch = null
        this.error = null
    }

    onRedirectToPerformView(batch, step) {
        const { history } = this.props

        const baseUrl = `/operations/production-request/${this.batch.production_request}/perform`
        const params = `?batch=${batch}&step=${step}`
        if (!window.location.pathname.startsWith(baseUrl) || !window.location.href.slice(baseUrl.length).includes(params)) {
            history.push(`${baseUrl}${params}&prev=${encodeURIComponent(window.location.pathname + window.location.search)}`)
        }
    }

    onScan = () => {
        const { operator, onStepSelect } = this.props
        this.error = null

        api.get('/batch/scan_to_perform/',
            {
                'serial_number': this.value,
                ...operator && { 'badge_id': operator.badgeId },
            })
            .then(res => {
                if ('load_carrier' in res) {
                    this.loadCarrier = res['load_carrier']
                } else if ('batch' in res) {
                    this.batch = res['batch']
                }

                if (this.loadCarrier !== null || this.batch.finished) {
                    this.showBatchInfo = true
                    return
                }

                const batch = `${this.batch.id}:${this.batch.quantity}`

                if (onStepSelect) {
                    onStepSelect(batch, res['next_step_id'])
                } else {
                    this.onRedirectToPerformView(batch, res['next_step_id'])
                }
                this.close()
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    switch (err.response.data.code) {
                        case 'missing_skills':
                            this.batch = err.response.data.batch
                            this.error = t('workStation.production.scanToPerformModal.error.missingSkills', { skills: err.response.data.message.join(', ') })
                            break;
                        case 'wrong_workstation':
                            this.batch = err.response.data.batch
                            this.error = t('workStation.production.scanToPerformModal.error.wrongWorkstation', { workstation: err.response.data.message })
                            break;
                        case 'not_found':
                            this.error = t('workStation.production.batchModal.error.notFound')
                            break;
                        case 'cannot_perform':
                            this.batch = err.response.data.batch
                            this.error = err.response.data.message
                            break;
                        default:
                            break;
                    }
                }
            })
    }

    renderOptions() {
        const { batches } = this.props

        if (!batches || batches.length === 0) {
            return
        }

        return (
            <Options>
                {batches.map((batch, i) =>
                    <React.Fragment key={`batch_${batch.cid}`}>
                        {i !== 0 && ', '}
                        <span onClick={() => {
                            this.onChange(batch.serialNumber)
                            this.onScan()
                        }}>
                            <code style={{ color: 'teal' }} data-test-batch-serial-number >{batch.serialNumber}</code>
                        </span>
                    </React.Fragment>
                )}
            </Options>
        )
    }

    render() {
        const { triggerProps } = this.props
        return (
            <Modal closeIcon data-test-scan-to-perform-modal
                open={this.isOpen}
                onClose={this.close}
                onUnmount={this.onUnmount}
                size="tiny"
                trigger={
                    <Button primary data-test-scan-to-perform-modal-button
                        {...triggerProps}
                        onClick={this.open}
                    />
                }
            >
                <Modal.Header>{t('workStation.production.scanToPerformModal.title')}</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.onScan}>
                        <TargetTextInput autoFocus
                            label={t('batch.field.serialNumber.label')}
                            name="serialNumber"
                            value={this.value}
                            onChange={this.onChange}
                            errors={this.error === null ? [] : [this.error]}
                        />
                    </Form>
                    {this.renderOptions()}
                    {this.showBatchInfo && (
                        <TargetInfoModal open
                            target={this.batch !== null ? this.batch : this.loadCarrier}
                            scale={1.5}
                            onClose={() => {
                                this.showBatchInfo = false
                                this.close()
                            }}
                        />
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button data-test-cancel-button
                        content={t('workStation.production.scanToPerformModal.cancelBtn')}
                        onClick={this.close}
                    />
                    <RightDivider />
                    <Button primary data-test-view-batch
                        icon="square-info"
                        labelPosition="left"
                        content={t('workStation.production.scanToPerformModal.infoBtn')}
                        onClick={() => this.showBatchInfo = true}
                        disabled={this.batch === null}
                    />
                    <Button primary data-test-start-button
                        icon="check"
                        labelPosition="left"
                        content={t('workStation.production.scanToPerformModal.confirmBtn')}
                        onClick={this.onScan}
                        disabled={this.error !== null || (this.batch !== null && this.batch.finished)}
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}
