import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'
import { TargetNumberInput } from 'spider/semantic-ui/Target'
import Decimal from 'decimal.js'
import styled from 'styled-components';

// components
import { FullWidthTable } from 'component/MaterialPlan/FormStep';
import PrintButtonModal from 'component/PrintButtonModal'
import { ProductionRequestDrawingImage } from 'component/ProductionRequest/DrawingImage'
// end components

// helpers
import getGlobalValue from 'helpers/getGlobalValue'
import { humanReadable } from 'helpers/decimal'
import { onEnter } from 'helpers'
import { getSubBatchInstructions } from 'helpers/print'
import { getExactShopOrders, getExactGlobeProductionOrders } from 'helpers/productionRequest'
// end helpers

// stores
import { ProductionRequest } from 'store/ProductionRequest'
import { Operator } from 'store/Operator'
// end stores


const CollectedSpan = styled.span`
  display: flex;
  min-width: 160px;
`


export default class SubBatches extends Component {
  /**
   * This form-field shows the sub-batches on a nest, and allows you to enter how many of a certain article type were actually collected.
   */
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest).isRequired,
    targetProps: PropTypes.object,
    data: PropTypes.object,
    field: PropTypes.object,
    errors: PropTypes.object,
    errorsAtPath: PropTypes.func,
    onAddRef: PropTypes.func,
    onFocus: PropTypes.func,
    autoFocus: PropTypes.bool,
    index: PropTypes.number,
    operator: PropTypes.instanceOf(Operator),
  }

  getImage(productionRequest) {
    return productionRequest.drawingImage || ''
  }

  onChangeNumberCollected(change, data, key, batch, onChange) {
    const formData = { ...data.form_data[key] }
    formData[batch.id] = Decimal.add(formData[batch.id], Decimal(change))
    onChange(formData)
  }

  getInstructions = async (productionRequest, batch, operator) => {
    const template = await getGlobalValue('sub_batches_print_label')
    return getSubBatchInstructions(template, productionRequest, batch, operator)
  }

  render() {
    const { productionRequest, targetProps, data, field, errors, errorsAtPath, onAddRef, onFocus, autoFocus, index, operator } = this.props
    const key = field.id.toString()   // Passing the key through properties did not work, since it got garbage collected, so it is re-derived with the field here.
    const batches = productionRequest.batches.filter(batch => batch.subProductionRequestBatchLinks.length > 0)
    return (
      <FullWidthTable>
        <thead>
          <th>{t('formStepField.field.subBatches.image')}</th>
          <th>{t('formStepField.field.subBatches.articleCode')}</th>
          <th>{t('formStepField.field.subBatches.articleName')}</th>
          <th>{t('formStepField.field.subBatches.nested')}</th>
          <th>{t('formStepField.field.subBatches.collected')}</th>
          <th>{t('formStepField.field.subBatches.superProductionRequest')}</th>
        </thead>
        {
          batches.map((batch, i) => {
            const exactShopOrders = getExactShopOrders(productionRequest);
            const exactGlobeProductionOrders = getExactGlobeProductionOrders(productionRequest);
            return (
              <tr>
                <td>
                  <ProductionRequestDrawingImage productionRequest={productionRequest}/>
                </td>
                <td data-test-sub-batch-article-code={batch.id}>{batch.batchType.articleType.code}</td>
                <td data-test-sub-batch-article-name={batch.id}>{batch.batchType.articleType.name}</td>
                <td data-test-sub-batch-nested={batch.id}>{humanReadable(batch.quantity)}</td>
                <td>
                  <CollectedSpan>
                    <Button
                      icon='minus'
                      onClick={() => this.onChangeNumberCollected(-1, data, key, batch, targetProps.onChange)}
                      data-test-sub-batch-minus={batch.id}
                    />

                    <TargetNumberInput data-test-sub-batch-collected={batch.id} fluid style={{ minWidth:'80px' }}
                        {...targetProps}
                        autoFocus={i === 0 && autoFocus}
                        contentProps={{ // This is for the auto focusing on enter press
                          ref: (ref) => {
                            if (ref) {
                              onAddRef(`field_${index}_${i}`, ref)
                            }
                          },
                        }}
                        value={data.form_data[key] ? humanReadable(data.form_data[key][batch.id]?.toString() ?? '') : ''}
                        onChange={(value) => {
                          const formData = { ...data.form_data[key] }
                          formData[batch.id] = Decimal(value === '' ? 0 : value)
                          targetProps.onChange(formData)
                        }}
                        errors={errorsAtPath(errors, 'form_data', key, batch.id.toString()).map(error => error.message)}
                        onKeyPress={onEnter(() => onFocus(i + 1))}
                    />
                    <Button
                      icon='plus'
                      onClick={() => this.onChangeNumberCollected(1, data, key, batch, targetProps.onChange)}
                      data-test-sub-batch-plus={batch.id}
                    />
                  </CollectedSpan>
                </td>
                <td data-test-sub-batch-production-order={batch.id}>
                  <PrintButtonModal data-test-print-button
                    key={key}
                    getInstructions={() => this.getInstructions(productionRequest, batch, operator)}
                    primary={false}
                    style={{ margin: '0 0 0 1rem !important' }}
                    copies={1}
                    alterButtons
                  />
                  {!productionRequest.productionOrder.isNew && productionRequest.productionOrder.getLabel()}
                  {exactShopOrders.map((exactShopOrder) => <React.Fragment key={exactShopOrder.cid}>{exactShopOrder.getLabel()}</React.Fragment>)}
                  {exactGlobeProductionOrders.map((exactGlobeProductionOrder) => <React.Fragment key={exactGlobeProductionOrder.cid}>{exactGlobeProductionOrder.getLabel()}</React.Fragment>)}
                </td>
              </tr>
            )
          })
        }
      </FullWidthTable>
    )
  }
}
