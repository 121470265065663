import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Step } from 'store/Step'
import { TargetNumberInput } from 'spider/semantic-ui/Target'

@observer
export default class PrintStepEdit extends Component {
  static propTypes = {
    step: PropTypes.instanceOf(Step).isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  render() {
    const { step, disabled } = this.props

    return <TargetNumberInput target={step.multiplierStep} name="multiplier" disabled={disabled} />
  }
}
