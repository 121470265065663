import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { computed, action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Form } from 'semantic-ui-react'
import { ArticleType } from 'store/ArticleType'
import { Warehouse } from 'store/Warehouse'
import { StorageLocation } from 'store/StorageLocation'
import { TargetSelect } from 'spider/semantic-ui/Target'


@observer
export default class StorageLocationSelect extends Component {
    static propTypes = {
        articleType: PropTypes.instanceOf(ArticleType).isRequired,
        warehouses: PropTypes.arrayOf(PropTypes.instanceOf(Warehouse).isRequired).isRequired,
        storageLocations: PropTypes.arrayOf(PropTypes.instanceOf(StorageLocation).isRequired).isRequired,
        onChange: PropTypes.func.isRequired,
        defaultWarehouse: PropTypes.instanceOf(Warehouse),
        showWarehouse: PropTypes.bool
    }

    static defaultProps = {
        defaultWarehouse: null,
        showWarehouse: true,
    }

    @observable warehouse = this.props.defaultWarehouse && this.props.defaultWarehouse.id
    @observable storageLocation = null

    @computed get storageLocations() {
        const { storageLocations } = this.props
        if (this.warehouse === null) {
            return []
        }
        return storageLocations.filter(({ warehouse }) => warehouse.id === this.warehouse)
    }

    focus() {
        if (this.warehouse) {
            this.storageLocationSelect.handleFocus()
        } else {
            this.warehouseSelect.handleFocus()
        }
    }

    render() {
        const { articleType, warehouses, onChange, defaultWarehouse, showWarehouse } = this.props

        return (
            <Form.Group widths="equal">
                {showWarehouse && (
                    <TargetSelect data-test-warehouse-select noLabel
                        type="int"
                        value={this.warehouse}
                        onChange={action((warehouse) => {
                            this.warehouse = warehouse
                            this.storageLocation = null
                            this.storageLocationSelect.handleFocus()
                        })}
                        options={warehouses.map((warehouse) => ({
                            value: warehouse.id,
                            text: `${warehouse.code}${warehouse.code !== '' && warehouse.name !== '' ? ' ' : ''}${warehouse.name}`,
                        }))}
                        disabled={!!defaultWarehouse}
                        contentProps={{ ref: (node) => this.warehouseSelect = node }}
                    />
                )}
                <TargetSelect data-test-storage-location-select noLabel search
                    type="int"
                    value={this.storageLocation}
                    onChange={action((storageLocation) => {
                        this.storageLocation = storageLocation
                        onChange(storageLocation)
                    })}
                    options={this.storageLocations.map((storageLocation) => {
                        const slx = articleType.storageLocations.find(asl => asl.storageLocation.id === storageLocation.id)
                        const inStockCountSuffix = slx ? ` (${slx.stock})` : ''

                        return {
                            value: storageLocation.id,
                            text: `${storageLocation.code}${storageLocation.code !== '' && storageLocation.name !== '' ? ' ' : ''}${storageLocation.name}${inStockCountSuffix}`,
                        }
                    })}
                    contentProps={{ ref: (node) => this.storageLocationSelect = node }}
                />
            </Form.Group>
        )
    }
}