import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Checkbox } from 'semantic-ui-react'
import { TargetTextInput } from 'spider/semantic-ui/Target'

const NullableTargetTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export default class NullableTargetTextInput extends TargetTextInput {
  renderContent({ disabled, ...props }) {
    return (
      <NullableTargetTextContainer>
        <Checkbox
          checked={this.value !== null}
          onChange={(e, { checked }) => {
            this.onChange(e, { value: checked ? '' : null })
            if (checked) {
              const input = ReactDOM.findDOMNode(this).querySelector('input[type="text"]')
              setTimeout(() => input.focus(), 0)
            }
          }}
          disabled={disabled}
        />
        {super.renderContent({ ...props, disabled: disabled || this.value === null })}
      </NullableTargetTextContainer>
    )
  }
}
