import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import styled, { css } from 'styled-components'
import { Icon, Popup } from 'semantic-ui-react'
import { Integration, TYPE_ICONS, TYPE_COLORS } from 'store/Integration'
import { DATETIME_FORMAT, TIME_FORMAT, snakeToCamel } from 'helpers'
import { ItemButton } from 'spider/semantic-ui/Admin/Overview'
import { SyncModal } from '/screen/Integration/SyncModal'
import subscribe from 'decorator/subscribe'
import moment from 'moment'


const IntegrationMonitorHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  > p {
    margin: 0;
  }
`

const IntegrationMonitorBar = styled.div`
  position: relative;
  height: 8px;
  margin-bottom: 4px;
  overflow: hidden;

  & > .service-status-bar-bg {
    height: 100%;
    background-color: #eee;
    border-radius: 4px;
  }

  & > .service-status-bar-fill {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 4px;
  }
`

const IntegrationMonitorBarTicks = styled.div`
  display: flex;
  height: 6px;
  margin-top: 2px;
  margin-bottom: 4px;

  & > * { height: 100%; }
`

const IntegrationMonitorBarBottomTicks = styled.div`
  height: 6px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
`

const IntegrationMonitorBarLabels = styled.div`
  position: relative;
  height: 16px;
  font-size: 12px;
  color: #666;

  & > * { position: absolute; }
`

const IntegrationMonitorBarBottomLabels = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666;
`

const IntegrationMonitorHighlight = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
  padding: 4px 10px;
  border-radius: 4px;

  ${({ warning }) =>
    warning
      ? css`
        background-color: rgba(255, 125, 0, 0.2);
      `
      : css`
        background-color: rgba(255, 0, 0, 0.2);
      `
  }
`

export const STATUS_COLORS = {
  'red': '#B71C1C',
  'orange': '#FFB300',
  'green': '#43A047',
}

@observer
@subscribe
export default class IntegrationMonitor extends Component {
  static propTypes = {
    integration: PropTypes.instanceOf(Integration).isRequired,
  }

  @observable integrationType = null
  @observable integrationActive = null

  checkStatusInterval = null

  componentDidMount() {
    let { integration } = this.props

    switch (integration.type) {
      case 'exact':
        integration = integration.exactIntegration
        break

      case 'unit4':
        integration = integration.unit4Integration
        break

      default:
        break
    }

    // Subscribe to websocket rooms
    if (this.props.integration.type === 'exact') {
      this.subscribe(
        { type: 'exact_api_status_update', integration: this.props.integration.id },
        action(() => {
          this.props.integration.fetch()
        })
      )

      this.subscribe(
        { type: 'exact_sync_service_status_update', integration: this.props.integration.id },
        action(() => {
          this.props.integration.fetch()
        }
      ))
    }

    // Subscribe to sync start notification
    this.subscribe(
      { type: 'integration_sync_start', integration: this.props.integration.id },
      action((data) => this.parse_sync_start(data))
    )

    this.subscribe(
      { type: 'integration_pending_performances_update', integration: this.props.integration.id },
      action((data) => {
        this.parse_sync_pending_performances_update(data)
      })
    )
  }

  parse_sync_start() {
    const { integration } = this.props
    integration.fetch()
  }

  parse_sync_pending_performances_update() {
    const { integration } = this.props
    integration.fetch()
  }

  render() {
    const { integration } = this.props

    const lastSyncedAt = integration.lastSyncedAt ? moment(integration.lastSyncedAt) : null
    const lsaFormatted = () => {
      if (lastSyncedAt && lastSyncedAt.isValid()) {
        // If the last synced at datetime is today, format it as a timestamp. Else, also display the date.
        return lastSyncedAt.isSame(moment(), 'day') ? lastSyncedAt.format(TIME_FORMAT) : lastSyncedAt.format(DATETIME_FORMAT)
      } else {
        // Display 'Never' if the last synced at datetime is null or invalid.
        return t('integrationsMonitor.syncServiceStatus.neverSynced')
      }
    }

    const apiRateLimitProgressBar = () => {
      if (integration.type !== 'exact') {
        return null
      }

      const apiReqRemainingFrac = integration.exactIntegration.apiRateLimitLimit > 0 ? integration.exactIntegration.apiRateLimitRemaining / integration.exactIntegration.apiRateLimitLimit : 1
      const apiReqUsedFrac = 1 - apiReqRemainingFrac

      const serviceStatusBarFillColor = () => {
        if (apiReqRemainingFrac === 0) {
          return STATUS_COLORS['red'] // Ran out of sync requests
        } else if (apiReqRemainingFrac < 0.25) {
          return STATUS_COLORS['orange'] // Less than 25% of sync requests remaining
        } else {
          return STATUS_COLORS['green']
        }
      }

      return (
        <div>
          <IntegrationMonitorBarLabels>
            <span style={{ 'left': apiReqUsedFrac * 100 + '%', 'transform': 'translateX(-50%)' }} data-test-api-status-app-header-req-used={integration.id}>{integration.exactIntegration.apiRateLimitLimit - integration.exactIntegration.apiRateLimitRemaining}</span>
          </IntegrationMonitorBarLabels>
          <IntegrationMonitorBarTicks>
            <div className="service-status-bar-1" style={{ 'width': apiReqUsedFrac * 100 + '%' }}></div>
            <div className="service-status-bar-2" style={{ 'width': 100 - apiReqUsedFrac * 100 + '%', 'borderLeft': '1px solid #ccc' }}></div>
          </IntegrationMonitorBarTicks>
          <IntegrationMonitorBar>
            <div className="service-status-bar-bg"></div>
            <div className="service-status-bar-fill" style={{ 'width': apiReqUsedFrac * 100 + '%', 'backgroundColor': serviceStatusBarFillColor() }}></div>
          </IntegrationMonitorBar>
          <IntegrationMonitorBarBottomTicks></IntegrationMonitorBarBottomTicks>
          <IntegrationMonitorBarBottomLabels>
            <span>0</span>
            <span>{integration.exactIntegration.apiRateLimitLimit}</span>
          </IntegrationMonitorBarBottomLabels>
        </div>
      )
    }

    const apiRateLimitWarning = () => {
      if (integration.type !== 'exact') {
        return null
      }

      const apiReqRemainingFrac = integration.exactIntegration.apiRateLimitLimit > 0 ? integration.exactIntegration.apiRateLimitRemaining / integration.exactIntegration.apiRateLimitLimit : 1

      if (apiReqRemainingFrac < 0.25 && apiReqRemainingFrac > 0) {
        return (
          <IntegrationMonitorHighlight warning={true} data-test-api-status-app-header-warning-hl={integration.id}>
            <Icon size='small' name='warning sign' />
            <span>{t('integrationsMonitor.apiRequests.almostOutOfRequests')}</span>
          </IntegrationMonitorHighlight>
        )
      } else if (apiReqRemainingFrac === 0) {
        return (
          <IntegrationMonitorHighlight warning={false} data-test-api-status-app-header-error-hl={integration.id}>
            <Icon size='small' name='times circle' />
            <Popup style={{ left: '-12px' }}
              trigger={<span>{t('integrationsMonitor.apiRequests.outOfRequests')}</span>}
              content={t('integrationsMonitor.apiRequests.outOfRequestsHelp')}
            />
          </IntegrationMonitorHighlight>
        )
      }
    }

    const syncIntegrationButton = () => {
      return (
        <>
          {integration.type && (
            <SyncModal
              integration={integration}
              trigger={
                <ItemButton fluid
                  data-test-integration-monitor-sync={integration.id}
                  icon="refresh"
                  content={t(`${snakeToCamel(integration.type)}Integration.overview.syncButton`)}
                  disabled={!integration.active}
                  style={{ marginTop: '8px' }}
                />
              }
            />
          )}
        </>
      )
    }

    return (
      <>
        <IntegrationMonitorHeader>
          <p style={{ 'fontSize': '16px' }}>
            <Popup style={{ left: '-12px' }}
              trigger={
                <Icon
                  size='small'
                  name={TYPE_ICONS[integration.type]}
                  style={{ color: TYPE_COLORS[integration.type], marginLeft:'0px', marginRight: '12px' }}
                />}
              content={t(`integration.field.type.value.${integration.type}`)}
            />
          <strong><span style={{ 'textTransform': 'capitalize' }}>{integration.type}</span> sync</strong>
          </p>
          <p data-test-last-sync-app-header={integration.id}>{t('integrationsMonitor.syncServiceStatus.lastSync')}: {lsaFormatted()}</p>
        </IntegrationMonitorHeader>

        {integration.type === 'exact' && (
          <p style={{ 'margin': '0' }} data-test-api-status-app-header={integration.id}>{t('integrationsMonitor.apiRequests.usedToday')}</p>
        )}

        {apiRateLimitProgressBar()}

        <p style={{ 'margin': '0' }} data-test-pending-performances-app-header={integration.id}>{t('integration.field.pendingPerformances.label')}: {integration.pendingPerformances}</p>

        {apiRateLimitWarning()}
        {syncIntegrationButton()}
      </>
    )
  }
}
