import React from 'react'
import PropTypes from 'prop-types'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { Button } from 'spider/semantic-ui/Button'
import { ProcessVersion } from 'store/ProcessVersion'
import { showNotification } from 'helpers/notification'

const CLIPBOARD_KEY = 'process-clipboard'

function splitRelations(relations) {
  const res = {}
  // eslint-disable-next-line
  for (const relation of relations) {
    let node = res
    // eslint-disable-next-line
    for (const key of relation.split('.')) {
      if (node[key] === undefined) {
        node[key] = {}
      }
      node = node[key]
    }
  }
  return res
}

const KEEP_FORM_IDS = [
  'fields.articleType',
  'fields.dataSource',
  'fields.metafield',
  'fields.scanConstraints.leftMetafield',
  'fields.scanConstraints.rightMetafield',
]

const KEEP_IDS = [
  'steps.capabilities',
  'steps.workStation',
  'steps.capabilities',

  ...KEEP_FORM_IDS.flatMap((rel) => [
    `steps.formStep.form.${rel}`,
    `steps.splitStep.form.${rel}`,
  ]),

  'steps.growStep.climateScript',
  'steps.growStep.lightScript',
  'steps.growStep.waterScript',

  'steps.nextStep',
]

function copy(processVersion) {
  const data = {
    steps: processVersion.steps.models.map((step) => ({
      ...step.toJS(),
      nextStep: processVersion.steps.models.findIndex((nextStep) => (
        nextStep.cid === step.nextStep.cid ||
        (nextStep.id !== null && nextStep.id === step.nextStep.id)
      )),
    })),
  }

  const stack = [[data, null, splitRelations(processVersion.__activeRelations)]]
  while (stack.length > 0) {
    let [data, path, rels] = stack.pop()
    if (KEEP_IDS.includes(path)) {
      continue
    }
    delete data.id
    // eslint-disable-next-line
    for (const [rel, subrels] of Object.entries(rels)) {
      let subdata = data[rel]
      if (subdata === undefined) {
        continue
      }
      if (!Array.isArray(subdata)) {
        subdata = [subdata]
      }
      const subpath = path ? `${path}.${rel}` : rel
      // eslint-disable-next-line
      for (const subdata_ of subdata) {
        stack.push([subdata_, subpath, subrels])
      }
    }
  }

  localStorage.setItem(CLIPBOARD_KEY, JSON.stringify(data))
}

function pasteFunction(processVersion) {
  const { steps, ...data } = JSON.parse(localStorage.getItem(CLIPBOARD_KEY))

  processVersion.steps.clear()
  const nextSteps = []
  // eslint-disable-next-line
  for (const { nextStep, ...step } of steps) {
    const newStep = processVersion.steps.add(step)
    newStep.markChanged('id')
    nextSteps.push(nextStep)
  }
  for (let step = 0; step < processVersion.steps.length; step++) {
    if (nextSteps[step] !== -1) {
      processVersion.steps.at(step).nextStep = processVersion.steps.at(nextSteps[step])
    }
  }

  // eslint-disable-next-line
  for (const [key, value] of Object.entries(data)) {
    if (processVersion.__activeCurrentRelations.includes(key)) {
      processVersion[key].parse(value)
    } else {
      processVersion.setInput(key, value)
    }
  }
}

const pasteAction = action(pasteFunction)

@observer
export class CopyButton extends Button {
  static propTypes = {
    processVersion: PropTypes.instanceOf(ProcessVersion).isRequired,
  }

  render() {
    const { processVersion, ...props } = this.props
    return (
      <Button primary data-test-process-copy-button
        icon="copy"
        content={t('articleType.edit.copyPaste.copyButton')}
        onClick={() => {
          copy(processVersion)
          showNotification(t('articleType.edit.copyPaste.copied'))
        }}
        {...props}
      />
    )
  }
}

@observer
export class PasteButton extends Button {
  static propTypes = {
    processVersion: PropTypes.instanceOf(ProcessVersion).isRequired,
  }

  render() {
    const { processVersion, ...props } = this.props
    return (
      <Button primary data-test-process-paste-button
        icon="paste"
        content={t('articleType.edit.copyPaste.pasteButton')}
        onClick={() => {
          try {
            pasteAction(processVersion)
            showNotification(t('articleType.edit.copyPaste.pasted'))
          } catch {
            showNotification({
              message: t('articleType.edit.copyPaste.pasteError'),
              error: true,
            })
          }
        }}
        {...props}
      />
    )
  }
}
