import { tint } from 'polished'


const IS_DEBUG = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
const IS_STAGE = typeof window !== 'undefined' && window.location && window.location.href.includes('staging')

const primaryColor = '#424242'
const secondaryColor = '#1e2033'
const accentColor = '#ff9040';

const COLOR_PRIMARY_PROD = '#212121'; // Production.
const COLOR_PRIMARY_STAGE = '#00BFA5';
const COLOR_PRIMARY_DEV = '#d19200';

function getMenuColor() {
  if (IS_STAGE) {
    return COLOR_PRIMARY_STAGE
  }

  if (IS_DEBUG) {
    return COLOR_PRIMARY_DEV
  }

  return COLOR_PRIMARY_PROD;
}

const menuColor = getMenuColor();

const tintedPrimaryColor = tint(0.15, primaryColor)
const toolbarColor = tint(0.15, menuColor)
const buttonColor = '#424242'
const dangerColor = '#dc0818'
const warningColor = '#ffc107'

const semanticUiRed = '#ff5252'
const semanticUiRedHover = '#ff3838'
const semanticUiGreen = '#21ba45'
const semanticUiGreenHover = '#16ab39'

export const workstationColors = {
  '#db2828': '#F4BFBF',
  '#f2711c': '#FBD4BB',
  '#fbbd08': '#FEEBB5',
  '#b5cc18': '#E9F0BA',
  '#21ba45': '#BCEAC7',
  '#00b5ad': '#B3E9E6',
  '#2185d0': '#BCDAF1',
  '#6435c9': '#D1C2EF',
  '#a333c8': '#E3C2EF',
  '#e03997': '#F6C4E0',
  '#a5673f': '#E4D1C5',
  '#767676': '#D6D6D6',
  '#1b1c1d': '#BBBBBB',
}


export const theme = {
  primaryColor,
  secondaryColor,
  menuColor,
  tintedPrimaryColor,
  toolbarColor,
  buttonColor,
  dangerColor,
  warningColor,
  accentColor,
  semanticUiRed,
  semanticUiRedHover,
  semanticUiGreen,
  semanticUiGreenHover,
}
