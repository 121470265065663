import React from 'react'
import PropTypes from 'prop-types'
import { TargetBase } from 'spider/semantic-ui/Target'
import { Icon } from 'semantic-ui-react'
import IconModal from './IconModal'
import styled from 'styled-components'

const CursorIcon = styled(Icon)`
  ${({ disabled }) =>
    disabled
      ? ''
      : `
        cursor: pointer;
    `}
  font-size: 1.5em !important;
`

const CursorText = styled.span`
  font-style: italic;
  ${({ disabled }) =>
    disabled
      ? ''
      : `
        cursor: pointer;
    `}
`

export default class TargetIcon extends TargetBase {
  static propTypes = {
    ...TargetBase.propTypes,
    value: PropTypes.string,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    ...TargetBase.defaultProps,
    disabled: false,
  }

  renderContent({ disabled }) {
    let node = this.value ? (
      <CursorIcon disabled={disabled} name={this.value} />
    ) : (
      <CursorText disabled={disabled}>{t('form.iconInput.noneSelected')}</CursorText>
    )

    if (!disabled) {
      node = <IconModal trigger={node} onSelect={this.onChange} />
    }

    return node
  }
}
